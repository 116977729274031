import { css } from '@emotion/react';
import { D1ImageType } from '../../generated/syncShared/d1ImageType';
import { IMAGE_TYPE_TO_SRC } from '../../res';
import { ClassNameProps } from '../../types/generalTypes';
import { CColor } from '../../util/commonCSS';

type Props = ClassNameProps & {
    image: D1ImageType;
    width?: number;
    color?: string;
    onClick?: () => void;
    onMouseDown?: () => void;
};

export default function D1Image({ image, width, color, ...props }: Props) {
    const clickable = props.onClick != null || props.onMouseDown != null;
    const src = IMAGE_TYPE_TO_SRC[image];
    const sharedCss = {
        cursor: clickable ? 'pointer' : undefined,
    };
    if (
        color != null &&
        (typeof src !== 'object' || src.type !== 'colornode')
    ) {
        return <>cannot use color here</>;
    }
    if (typeof src === 'object') {
        if (src.type === 'node') {
            return (
                <div
                    css={css(sharedCss, {
                        width: width == null ? undefined : `${width}px`,
                        minWidth: width == null ? undefined : `${width}px`,
                    })}
                    {...props}
                >
                    {src.component}
                </div>
            );
        } else if (src.type === 'colornode') {
            const Component = src.component;
            return (
                <div
                    css={css(sharedCss, {
                        width: width == null ? undefined : `${width}px`,
                        minWidth: width == null ? undefined : `${width}px`,
                    })}
                    {...props}
                >
                    <Component
                        css={css({ width: '100%', height: '100%' })}
                        color={color ?? CColor.h000000}
                    />
                </div>
            );
        }
    }
    return (
        <img src={src as any} css={css(sharedCss)} width={width} {...props} />
    );
}
