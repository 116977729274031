// [HybridCodegen] hash b6b68a13f214235f701c67a876a989cf

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';
import withParams from '../../../util/withParams';

const Component = lazy(async () => {
    const module = await import(
        '../../../pages/applicant/home/previewAppTemplate/applicantPreviewAppTemplate.route'
    );
    const Component = module.RouteApplicantPreviewAppTemplate.component;
    const wrappers = module.RouteApplicantPreviewAppTemplate.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
const WithParams = withParams(Component, { appTemplate: 'int' });
export const ApplicantPreviewAppTemplateRoute: RouteObject = {
    path: 'home/appTemplate/:appTemplate',

    element: <WithParams />,
};
