import { css } from '@emotion/react';
import { ChildrenProps, ClassNameProps } from '../../../types/generalTypes';

type Props = ChildrenProps &
    ClassNameProps & {
        weight?: 300 | 400 | 500 | 600; // default: 500
        size: number;
        color: string;
        lineHeight?: number;
        onClick?: () => void;
        align?: 'right';
    };

const style = css({
    fontFamily: 'Poppins',
    fontStyle: 'normal',
});

export function D1TextBase({
    weight,
    size,
    color,
    lineHeight,
    align,
    ...props
}: Props) {
    return (
        <div
            css={[
                style,
                css({
                    fontWeight: weight ?? 500,
                    fontSize: `${size}px`,
                    color,
                    lineHeight:
                        lineHeight != null ? `${lineHeight}px` : undefined,
                    textAlign: align,
                }),
            ]}
            {...props}
        />
    );
}
