import { css } from '@emotion/react';

export const CColor = {
    appReviewSidebar: 'rgba(36, 150, 144, 0.04)',
    customizeApplicationFieldBackground: 'rgba(122, 213, 208, 0.15)',
    customizeApplicationFieldBackgroundHidden: 'rgba(190, 190, 190, 0.15)',
    customizeApplicationFieldBorder: 'rgba(36, 150, 144, 0.2)',
    customizeApplicationFieldBorderHidden: ' rgba(128, 128, 128, 0.2)',
    customizeApplicationTaskBackground: 'rgba(36, 150, 144, 0.08)',
    customizeApplicationTaskBackgroundHidden: 'rgba(238, 238, 238, 0.53)',
    h000000: '#000000',
    h0032E1: '#0032E1',
    h171717: '#171717',
    h1D1D1D: '#1D1D1D',
    h249690: '#249690',
    h313131: '#313131',
    hA5774D: '#A5774D',
    hC7E5E4: '#C7E5E4',
    h249791: '#249791',
    h323232: '#323232',
    h363636: '#363636',
    hA0A0A0: '#A0A0A0',
    h373737: '#373737',
    h393939: 'rgb(57, 57, 57)',
    h404040: '#404040',
    h41C1BA: '#41C1BA',
    h444444: '#444444',
    h474747: '#474747',
    h939393: '#939393',
    h49AA53: '#49AA53',
    h4A4A4A: '#4A4A4A',
    h4C8582: '#4C8582',
    h51C1BB: '#51C1BB',
    h535353: '#535353',
    h505050: '#505050',
    h585858: '#585858',
    h596173: '#596173',
    h5A5A5A: '#5A5A5A',
    h5E5E5E: '#5E5E5E',
    h606060: '#606060',
    h707070: '#707070',
    h7E7E7E: '#7E7E7E',
    h848484: '#848484',
    h868686: '#868686',
    h8A8A8A: '#8A8A8A',
    h8E8E8E: '#8E8E8E',
    h909090: '#909090',
    h94D2CF: '#94D2CF',
    h959595: '#959595',
    h999999: '#999999',
    hB0B0B0: '#B0B0B0',
    hB1A24C: '#B1A24C',
    hBCBCBC: '#BCBCBC',
    hC4C4C4: '#C4C4C4',
    hC6C6C6: '#C6C6C6',
    hCBCBCB: '#CBCBCB',
    hCCCCCC: '#CCCCCC',
    hCDCDCD: '#CDCDCD',
    hCFCFCF: '#CFCFCF',
    hD1D1D1: '#D1D1D1',
    hD3D3D3: '#D3D3D3',
    hD66262: '#D66262',
    hD96161: '#D96161',
    hD9D9D9: '#D9D9D9',
    hDBDBDB: '#DBDBDB',
    hDCDCDC: '#DCDCDC',
    hE0E0E0: '#E0E0E0',
    hE4E4E4: '#E4E4E4',
    hEDF3F3: '#EDF3F3',
    hE9F3F2: '#E9F3F2',
    hF0F0F0: '#F0F0F0',
    hF2F9F8: '#F2F9F8',
    hF3F3F3: '#F3F3F3',
    hF7F7F7: '#F7F7F7',
    hF8F8F8: '#F8F8F8',
    hF8FFF8: '#F8FFF8',
    hFBFFE0: '#FBFFE0',
    hFCFCFC: '#FCFCFC',
    hFDFDFD: '#FDFDFD',
    hBEBEBE: '#BEBEBE',
    h9E9E9E: '#9E9E9E',
    hFF9F2D: '#FF9F2D',
    hFFF4F4: '#FFF4F4',
    hFFFFFF: '#FFFFFF',
    interviewBreakBackground: '#ECF8F7',
    interviewBreakBorder: '#C3E3E2',
    interviewBreakText: '#249690',
    mealBreakBackground: '#F8F6EC',
    mealBreakBorder: '#CAC18E',
    mealBreakText: '#968524',
    primaryTransparent: 'rgba(79, 208, 201, 0.20)',
    programSelectionBG: 'rgba(36, 150, 144, 0.06)',
    redTransparent: 'rgba(252, 126, 109, 0.20)',
    statusGreenBG: 'rgba(73, 170, 83, 0.12)',
    statusRedBG: 'rgba(233, 49, 49, 0.12)',
    statusYellowBG: 'rgba(233, 221, 49, 0.22)',
    transparentDropDown: 'rgba(36, 150, 144, 0.2)',
    facultyManageReviewCardBG:
        'linear-gradient(0deg, rgba(36, 150, 144, 0.08) 0%, rgba(36, 150, 144, 0.08) 100%)',
};

export const CGradient = {
    greenGradient: `linear-gradient(96.75deg, ${CColor.h41C1BA} 0%, ${CColor.h596173} 133.3%)`,
    rootSignInButton: `linear-gradient(96.9deg, ${CColor.h41C1BA} 0%, ${CColor.h596173} 100%)`,
    navLeft: `linear-gradient(180deg, #4A9EA0 0%, #547682 100%)`,
};

export class C {
    static flexGrow1 = css({ flexGrow: 1 });

    static display = {
        flex: css({ display: 'flex' }),
    };

    static w100 = css({ width: '100%' });
    static h100 = css({ height: '100%' });

    static position = {
        relative: css({ position: 'relative' }),
    };

    static overflow = {
        hidden: css({ overflow: 'hidden' }),
    };

    static maybePX(px: number | null | undefined): string | undefined {
        return px == null ? undefined : `${px}px`;
    }
}
