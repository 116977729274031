import { useEffect, useState } from 'react';
import { D1Button, D1ButtonStyle } from '../components/design/d1button';
import { D1Flex } from '../components/design/d1flex';
import { D1Modal } from '../components/design/d1modal/d1modal';
import { ChildrenProps } from '../types/generalTypes';

export const staticSetError: {
    current: ((error: string) => void) | null;
} = { current: null };

export function SET_ROOT_MESSAGE(message: string) {
    if (staticSetError.current === null) {
        alert(message);
        window.location.reload();
    } else {
        staticSetError.current(message);
    }
}

export function SET_ROOT_ERROR(prefix: string, e: any) {
    console.error(`${prefix} error`, e);
    // Sentry.captureException(e);
    SET_ROOT_MESSAGE(
        'Sorry, an error has occurred. Please retry what you were doing.'
    );
}

export function ProvideRootErrorContext({ children }: ChildrenProps) {
    const [error, setError] = useState<string | null>(null);
    useEffect(() => {
        staticSetError.current = setError;
    }, []);
    return (
        <>
            {children}
            <D1Modal
                purpose="rootErrorModal"
                show={error !== null}
                zIndex={1000}
            >
                <D1Flex dir="y" gap={10} alignItems="center">
                    {error}
                    <D1Button
                        style={D1ButtonStyle.Success({ size: 'md' })}
                        onClick={() => window.location.reload()}
                    >
                        Reload page
                    </D1Button>
                </D1Flex>
            </D1Modal>
        </>
    );
}
