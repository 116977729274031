import { css } from '@emotion/react';
import { useEffect, useRef, useState } from 'react';
import { CColor } from '../../util/commonCSS';
import { D1TextBase } from '../design/text/d1textBase';

export type ArrowOption = {
    id: string;
    display: string;
};

export type ArrowOptionsProps = {
    options: ArrowOption[];
    hovered: string;
    onHover: (optionID: string) => void;
    onSelect: () => void;
    maxHeight?: number;
    width?: number;
    onBlur?: () => void;
};

export function ArrowOptions({
    options,
    hovered,
    onHover,
    onSelect,
    maxHeight,
    width,
    onBlur,
}: ArrowOptionsProps) {
    const ulRef = useRef<HTMLUListElement | null>(null);
    const { current: optionRefs } = useRef<Record<string, HTMLLIElement>>({});
    const [keyPress, setKeyPress] = useState<string | null>(null);

    useEffect(() => {
        const { current: ul } = ulRef;
        if (ul === null) return;
        if (onBlur != null) {
            ul.focus();
        }
    }, [options, onBlur]);

    useEffect(() => {
        if (keyPress === null) return;
        setKeyPress(null);
        let arrowPress = 0;
        switch (keyPress) {
            case 'ArrowDown':
                arrowPress = 1;
                break;
            case 'ArrowUp':
                arrowPress = -1;
                break;
            case 'Enter':
                onSelect();
                return;
            default:
                return;
        }
        let curIdx = options.map((opt) => opt.id).indexOf(hovered);
        if (curIdx === -1) return;
        curIdx += arrowPress;
        if (curIdx < 0) curIdx += options.length;
        if (curIdx >= options.length) curIdx -= options.length;
        onHover(options[curIdx].id);
    }, [keyPress, hovered, onHover, options, onSelect]);

    useEffect(() => {
        const listener = (e: KeyboardEvent) => {
            switch (e.key) {
                case 'ArrowDown':
                case 'ArrowUp':
                case 'Enter':
                    e.stopPropagation();
                    e.preventDefault();
                    setKeyPress(e.key);
                    break;
            }
        };
        document.addEventListener('keydown', listener);
        return () => {
            document.removeEventListener('keydown', listener);
        };
    }, []);

    useEffect(() => {
        const ul = ulRef.current;
        if (ul === null || !(hovered in optionRefs)) return;
        const visibleStart = ul.scrollTop;
        const visibleEnd = visibleStart + ul.clientHeight;
        const el = optionRefs[hovered];
        const elStart = el.offsetTop;
        const elEnd = elStart + el.clientHeight;
        if (elEnd > visibleEnd) {
            ul.scrollTop = elEnd - ul.clientHeight;
        } else if (elStart < visibleStart) {
            ul.scrollTop = elStart;
        }
    }, [hovered, optionRefs]);

    return (
        <ul
            css={css({
                background: 'white',
                listStyleType: 'none',
                padding: 0,
                margin: 0,
                boxShadow: `0px 0px 12px 2px rgba(0, 0, 0, 0.1)`,
                borderRadius: '8px',
            })}
            ref={ulRef}
            style={{
                maxHeight: maxHeight == null ? undefined : `${maxHeight}px`,
                overflow: 'auto',
                outline: 'none',
                cursor: 'default',
                width: width == null ? undefined : `${width}px`,
            }}
            onBlur={onBlur}
            tabIndex={onBlur != null ? -1 : undefined}
        >
            {options.map((option) => {
                const selected = option.id === hovered;
                return (
                    <li
                        ref={(el) => {
                            if (el === null) {
                                delete optionRefs[option.id];
                            } else {
                                optionRefs[option.id] = el;
                            }
                        }}
                        key={option.id}
                        css={css([
                            {
                                padding: '8px 10px',
                            },
                            selected && {
                                background: CColor.h249690,
                            },
                        ])}
                        onMouseDown={(e) => {
                            e.preventDefault();
                            onHover(option.id);
                            onSelect();
                        }}
                        onMouseOver={() => {
                            onHover(option.id);
                        }}
                        data-cy="arrow-option-li"
                    >
                        <D1TextBase
                            weight={400}
                            size={14}
                            lineHeight={18}
                            color={selected ? CColor.hFFFFFF : CColor.h5E5E5E}
                        >
                            {option.display}
                        </D1TextBase>
                    </li>
                );
            })}
        </ul>
    );
}
