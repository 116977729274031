import { css } from '@emotion/react';
import React from 'react';
import {
    ChildrenProps,
    ClassNameProps,
    MyGlobalProps,
} from '../../types/generalTypes';

type Props = ChildrenProps &
    ClassNameProps &
    MyGlobalProps & {
        dir: 'x' | 'y' | 'x-reverse' | 'y-reverse';
        gap?: number;
        gapStr?: string;
        colGap?: number;
        rowGap?: number;
        inline?: boolean;
        justifyContent?: 'center' | 'right';
        alignItems?: 'center' | 'baseline' | 'flex-end' | 'flex-start';
        wrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
        onClick?: () => void;
    };

const dirMap: Record<
    Props['dir'],
    'row' | 'column' | 'row-reverse' | 'column-reverse'
> = {
    x: 'row',
    y: 'column',
    'x-reverse': 'row-reverse',
    'y-reverse': 'column-reverse',
};

export const D1Flex = React.forwardRef<HTMLDivElement, Props>(
    (
        {
            children,
            dir,
            gap: gapNum,
            gapStr,
            colGap,
            rowGap,
            inline,
            justifyContent,
            alignItems,
            wrap: flexWrap,
            ...props
        },
        ref
    ) => {
        let gap: string | undefined = undefined;
        if (gapNum != null) {
            gap = `${gapNum}px`;
        } else if (gapStr != null) {
            gap = gapStr;
        }
        return (
            <div
                ref={ref}
                css={css({
                    display: inline ? 'inline-flex' : 'flex',
                    flexDirection: dirMap[dir],
                    gap,
                    columnGap: colGap == null ? undefined : `${colGap}px`,
                    rowGap: rowGap == null ? undefined : `${rowGap}px`,
                    justifyContent,
                    alignItems,
                    flexWrap,
                })}
                {...props}
            >
                {children}
            </div>
        );
    }
);
