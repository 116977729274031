import React from 'react';
import { ChildrenProps } from '../types/generalTypes';

export type WrapperWithProps = {
    component: React.ComponentType<any>;
    props: any;
};

export type Wrapper = React.ComponentType<ChildrenProps> | WrapperWithProps;

export function applyWrappers(
    wrappers: Wrapper[],
    node: React.ReactNode
): JSX.Element {
    let element = node;
    for (let i = wrappers.length - 1; i >= 0; i--) {
        let Component = wrappers[i];
        let props: any = {};
        if (typeof Component === 'object') {
            props = Component.props;
            Component = Component.component;
        }
        element = <Component {...props}>{element}</Component>;
    }
    return <>{element}</>;
}

export function wrapperProps<T>(
    component: React.ComponentType<T>,
    props: Omit<T, 'children'>
): WrapperWithProps {
    return {
        component,
        props,
    };
}
