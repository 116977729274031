// [HybridCodegen] hash 7fb6a4ad894050a72572b108112bbc3f

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';

const Component = lazy(async () => {
    const module = await import(
        '../../../pages/acceptTermsOfService/acceptTermsOfService.route'
    );
    const Component = module.RouteAcceptTermsOfService.component;
    const wrappers = module.RouteAcceptTermsOfService.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
export const AcceptTermsOfServiceRoute: RouteObject = {
    path: '/termsOfService',

    element: <Component />,
};
