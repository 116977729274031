// [HybridCodegen] hash ef7fd8ec186d091c0e75e1d2e4d9f295

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';

const Component = lazy(async () => {
    const module = await import(
        '../../../pages/faculty/interviewManager/facultyInterviewManager.route'
    );
    const Component = module.RouteFacultyInterviewManager.component;
    const wrappers = module.RouteFacultyInterviewManager.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
export const FacultyInterviewManagerRoute: RouteObject = {
    path: 'interviewManager',

    element: <Component />,
};
