import React from 'react';
import { createRoot } from 'react-dom/client';
import 'react-tooltip/dist/react-tooltip.css';
import { ProvideAuthContext } from './contexts/authContext/authContext';
import { ProvideAutoPopContext } from './contexts/autoPopContext/autoPopContext';
import { ProvideRootErrorContext } from './contexts/rootErrorContext';
import { ProvideUserPhotoContext } from './contexts/userPhotoContext';
import Router from './core/router';
import { ProvideUnsavedChanges } from './core/unsavedChanges';
import { ProvideCustomModal } from './hooks/useCustomModal';
import { ProvideEasyModal } from './hooks/useEasyModal';
import './styles/misc.scss';
import './styles/reactPhoneInput2Styles.scss';
import './styles/yesnessReactInputStyles.scss';
import { applyWrappers, Wrapper } from './util/wrapperUtil';

const container = document.getElementById('root')!;
const root = createRoot(container);

const wrappers: Wrapper[] = [
    ProvideRootErrorContext,
    ProvideAuthContext,
    ProvideAutoPopContext,
    ProvideUserPhotoContext,
    ProvideUnsavedChanges,
    ProvideEasyModal,
    ProvideCustomModal,
];

// const curHost = window.location.host;
// const testHosts = ['sandbox.accessgme.com', 'localhost:1234'];
// const allHosts = ['centralapp.accessgme.com', ...testHosts];

// if (testHosts.includes(curHost)) {
//     (window as any).LCA_DEV_MODE = true;
// }

// if (!allHosts.includes(curHost)) {
//     window.location.href = `https://centralapp.accessgme.com`;
// } else {
//     root.render(
//         <React.StrictMode>
//             {applyWrappers(wrappers, <Router />)}
//         </React.StrictMode>
//     );
// }

root.render(
    <React.StrictMode>
        {applyWrappers(wrappers, <Router />)}
    </React.StrictMode>
);
