// [HybridCodegen] hash 6524fb5e82e32cdd02656424d8555c54

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';
import withParams from '../../../util/withParams';

const Component = lazy(async () => {
    const module = await import(
        '../../../pages/public/pdfRender/pdfRender.route'
    );
    const Component = module.RoutePdfRender.component;
    const wrappers = module.RoutePdfRender.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
const WithParams = withParams(Component, { token: 'string' });
export const PdfRenderRoute: RouteObject = {
    path: '/pdfRender/:token',

    element: <WithParams />,
};
