import { css } from '@emotion/react';
import { ClassNameProps, MaybeChildrenProps } from '../../types/generalTypes';

type Props = MaybeChildrenProps &
    ClassNameProps & {
        noBasis?: boolean;
    };

export function D1FlexGrow1({ children, noBasis, ...props }: Props) {
    return (
        <div
            css={css({
                flexGrow: 1,
                flexBasis: noBasis ? 0 : undefined,
            })}
            {...props}
        >
            {children}
        </div>
    );
}
