import { css } from '@emotion/react';

export enum D1SpinnerSize {
    Small,
    Medium,
    Large,
}

type Props = {
    size?: D1SpinnerSize;
};

const config: Record<
    D1SpinnerSize,
    {
        px: number;
        border: number;
    }
> = {
    [D1SpinnerSize.Small]: { px: 16, border: 0.15 },
    [D1SpinnerSize.Medium]: { px: 26, border: 0.25 },
    [D1SpinnerSize.Large]: { px: 40, border: 0.4 },
};

export function D1Spinner({ size }: Props) {
    const cfg = config[size ?? D1SpinnerSize.Medium];
    return (
        <div
            css={css({
                display: 'inline-block',
                width: `${cfg.px}px`,
                height: `${cfg.px}px`,
                verticalAlign: 'text-bottom',
                border: `${cfg.border}em solid currentColor`,
                borderRightColor: 'transparent',
                borderRadius: '50%',
                animation: 'spinner-border .75s linear infinite',
            })}
        />
    );
}
