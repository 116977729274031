// [HybridCodegen] hash df1519cac23029cb40e385ab9f1f3e56

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';

const Component = lazy(async () => {
    const module = await import(
        '../../../pages/faculty/groups/facultyGroups.route'
    );
    const Component = module.RouteFacultyGroups.component;
    const wrappers = module.RouteFacultyGroups.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
export const FacultyGroupsRoute: RouteObject = {
    path: 'groups',

    element: <Component />,
};
