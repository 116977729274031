// [HybridCodegen] hash 3b13d5df4c7909e75f54bf2c1e9765d7
import * as S from './schema';

export enum ChangePasswordResponse {
    Success = 'Success',
    InvalidPassword = 'InvalidPassword',
}

export enum FileApiOperation {
    Upload = 'Upload',
    Download = 'Download',
}

export enum ChangeEmailResponse {
    Success = 'Success',
    EmailTaken = 'EmailTaken',
    InvalidToken = 'InvalidToken',
}

export enum LetterOfRecommendation_ApplicantStatusUpdate {
    Cancelled = 'Cancelled',
    Selected = 'Selected',
    Deselected = 'Deselected',
}

export enum ProgInstanceSignal_Type {
    Gold = 'Gold',
    Silver = 'Silver',
    Regular = 'Regular',
}

export enum ProgramStatus {
    Active = 'Active',
    Hidden = 'Hidden',
}

export enum ProgramUsing {
    PscaOnly = 'PscaOnly',
    Both = 'Both',
    Undecided = 'Undecided',
}

export enum AppAssistDocumentType {
    Transcript = 'Transcript',
    MSPE = 'MSPE',
}

export enum AppInstanceState {
    Open = 'Open',
    Submitted = 'Submitted',
}

export enum ApplicantScoreType {
    Step1 = 'Step1',
    Step2 = 'Step2',
    Comlex1 = 'Comlex1',
    Comlex2 = 'Comlex2',
    FailedPastExams = 'FailedPastExams',
}

export enum FrequentQuestionFormat {
    Text = 'Text',
    Html = 'Html',
}

export enum LetterOfRecommendationStatus {
    PendingAcceptance = 'PendingAcceptance',
    Accepted = 'Accepted',
    Declined = 'Declined',
    Cancelled = 'Cancelled',
    Submitted = 'Submitted',
    Import_PendingApplicantSelect = 'Import_PendingApplicantSelect',
    Import_ApplicantSelected = 'Import_ApplicantSelected',
}

export enum MfaOptionType {
    SMS = 'SMS',
}

export enum ProgramManagerVariant {
    Director = 'Director',
    Coordinator = 'Coordinator',
}

export enum ReviewerAppInstanceState {
    Open = 'Open',
    Submitted = 'Submitted',
}

export enum StripePaymentStatus {
    Canceled = 'Canceled',
    Processing = 'Processing',
    RequiresAction = 'RequiresAction',
    RequiresCapture = 'RequiresCapture',
    RequiresConfirmation = 'RequiresConfirmation',
    RequiresPaymentMethod = 'RequiresPaymentMethod',
    Succeeded = 'Succeeded',
    Unknown = 'Unknown',
}

export enum UserRole {
    Applicant = 'Applicant',
    ProgramDirector = 'ProgramDirector',
    ProgramCoordinator = 'ProgramCoordinator',
    Recommender = 'Recommender',
    Reviewer = 'Reviewer',
}

export type AccountAccessApi = {
    name: 'AccountAccessApi';
    fields: {
        accessors: Array<AccountAccessor>;
        accessees: Array<AccountAccessor>;
        numAccesses: number;
        hasSourceUser: boolean;
        loginAsUser: (userID: number) => string;
        loginAsSourceUser: string;
        queryAllAccessors: (query: string) => Array<AccountAccessor>;
        updateAccess: (id: number, access: boolean) => boolean;
    };
    queryFields:
        | 'accessors'
        | 'accessees'
        | 'numAccesses'
        | 'hasSourceUser'
        | 'queryAllAccessors';
    mutationFields: 'loginAsUser' | 'loginAsSourceUser' | 'updateAccess';
};

export type AnyUserApi = {
    name: 'AnyUserApi';
    fields: {
        self: User;
        updateInformation: (update: S.InformationUpdateInput) => boolean;
        changePassword: (
            oldPassword: string,
            newPassword: string
        ) => ChangePasswordResponse;
        acceptTos: boolean;
    };
    queryFields: 'self';
    mutationFields: 'updateInformation' | 'changePassword' | 'acceptTos';
    navigationFields: 'self';
};

export type ApplicantApi = {
    name: 'ApplicantApi';
    fields: {
        allSpecialties: Array<Specialty>;
        loadAppTemplate: (appTemplateID: number) => AppTemplate | null;
        loadAppInstance: (appInstanceID: number) => AppInstance | null;
        loadAllAppInstances: Array<AppInstance>;
        createAppInstance: (appTemplateID: number) => AppInstance;
    };
    queryFields:
        | 'allSpecialties'
        | 'loadAppTemplate'
        | 'loadAppInstance'
        | 'loadAllAppInstances';
    mutationFields: 'createAppInstance';
    navigationFields:
        | 'allSpecialties'
        | 'loadAppTemplate'
        | 'loadAppInstance'
        | 'loadAllAppInstances';
};

export type FileApi = {
    name: 'FileApi';
    fields: {
        getSignedUrl: (operation: FileApiOperation) => string;
        getUploadUrl: string;
        getDownloadUrl: string;
    };
    queryFields: 'getSignedUrl' | 'getUploadUrl' | 'getDownloadUrl';
};

export type FrequentQuestionApi = {
    name: 'FrequentQuestionApi';
    fields: {
        getQuestions: Array<FrequentQuestion>;
    };
    queryFields: 'getQuestions';
};

export type ApplicantAppInstanceApi = {
    name: 'ApplicantAppInstanceApi';
    fields: {
        updateResponses: (payload: string) => boolean;
        applicantSubmit: boolean;
        applicantReopen: boolean;
        applicantLORApi: ApplicantLORApi;
    };
    queryFields: 'applicantLORApi';
    mutationFields: 'updateResponses' | 'applicantSubmit' | 'applicantReopen';
    navigationFields: 'applicantLORApi';
};

export type ApplicantLORApi = {
    name: 'ApplicantLORApi';
    fields: {
        listRecommenders: Array<Recommender>;
        requestInternalRecommendation: (
            recommenderID: number
        ) => LetterOfRecommendation;
        requestExternalRecommendation: (
            param: S.RequestExternalRecommendationParamInput
        ) => RequestExternalRecommendationResult;
    };
    queryFields: 'listRecommenders';
    mutationFields:
        | 'requestInternalRecommendation'
        | 'requestExternalRecommendation';
    navigationFields: 'listRecommenders';
};

export type PMCustomizeApplicationApi = {
    name: 'PMCustomizeApplicationApi';
    fields: {
        setTaskVisibility: (taskKey: string, visible: boolean) => boolean;
        setFieldVisibility: (fieldKey: string, visible: boolean) => boolean;
    };
    mutationFields: 'setTaskVisibility' | 'setFieldVisibility';
};

export type ProgramUserApi = {
    name: 'ProgramUserApi';
    fields: {
        myProgram: Program;
        progTemplate: (id: number) => ProgTemplate;
    };
    queryFields: 'myProgram' | 'progTemplate';
    navigationFields: 'myProgram' | 'progTemplate';
};

export type PublicApi = {
    name: 'PublicApi';
    fields: {
        pendingAccountLink: (linkKey: string) => PendingAccountLink | null;
        emailVerification: (verifyKey: string) => EmailVerification | null;
        sendHelpRequest: (params: S.SendHelpRequestParamsInput) => boolean;
        termsOfServiceContent: TermsOfServiceContent;
        getApplicationForPDFRender: (
            progInstancePDFToken: string
        ) => ApplicationForReview;
        userAuthApi: UserAuthApi;
        mfaApi: MfaApi;
    };
    queryFields:
        | 'pendingAccountLink'
        | 'emailVerification'
        | 'termsOfServiceContent'
        | 'getApplicationForPDFRender'
        | 'userAuthApi'
        | 'mfaApi';
    mutationFields: 'sendHelpRequest';
    navigationFields:
        | 'pendingAccountLink'
        | 'emailVerification'
        | 'userAuthApi'
        | 'mfaApi';
};

export type MfaApi = {
    name: 'MfaApi';
    fields: {
        addSMSOption: (mfaToken: string, phone: string) => string | null;
        validateAddSMSOption: (
            addSMSToken: string,
            code: string
        ) => LoginSuccessResponse | null;
        validateLoginSMSCode: (
            mfaToken: string,
            code: string
        ) => LoginSuccessResponse | null;
    };
    mutationFields:
        | 'addSMSOption'
        | 'validateAddSMSOption'
        | 'validateLoginSMSCode';
};

export type UserAuthApi = {
    name: 'UserAuthApi';
    fields: {
        register: (registerParam: S.RegisterParamInput) => RegisterResponse;
        login: (email: string, password: string) => UserAuthMFAToken | null;
        loginWithLoginLink: (linkKey: string) => string | null;
        refresh: string | null;
        logout: boolean;
        resendVerifyEmail: (token: string) => boolean;
        forgotPassword: (email: string) => boolean;
    };
    queryFields: 'loginWithLoginLink' | 'refresh';
    mutationFields:
        | 'register'
        | 'login'
        | 'logout'
        | 'resendVerifyEmail'
        | 'forgotPassword';
};

export type GlobalSettings = {
    name: 'GlobalSettings';
    fields: {
        globalSettingsJson: string;
    };
    queryFields: 'globalSettingsJson';
};

export type SuperQLLoader = {
    name: 'SuperQLLoader';
    fields: {
        loadAdministrator: (id: number) => Administrator;
        loadAppAssistDocument: (id: number) => AppAssistDocument;
        loadAppAssister: (id: number) => AppAssister;
        loadAppGroup: (id: number) => AppGroup;
        loadAppInstance: (id: number) => AppInstance;
        loadApplicant: (id: number) => Applicant;
        loadInterviewBreak: (id: number) => InterviewBreak;
        loadInterviewDay: (id: number) => InterviewDay;
        loadInterviewRoom: (id: number) => InterviewRoom;
        loadLetterOfRecommendation: (id: number) => LetterOfRecommendation;
        loadPdfRenderJob: (id: number) => PdfRenderJob;
        loadProgInstance: (id: number) => ProgInstance;
        loadProgTemplate: (id: number) => ProgTemplate;
        loadProgram: (id: number) => Program;
        loadProgramTeamInvite: (id: number) => ProgramTeamInvite;
        loadProgramUser: (id: number) => ProgramUser;
        loadRecommender: (id: number) => Recommender;
        loadReviewerAppInstance: (id: number) => ReviewerAppInstance;
        loadUser: (id: number) => User;
        loadZipJob: (id: number) => ZipJob;
        loadApplicantScores: (id: number) => ApplicantScores;
    };
    queryFields:
        | 'loadAdministrator'
        | 'loadAppAssistDocument'
        | 'loadAppAssister'
        | 'loadAppGroup'
        | 'loadAppInstance'
        | 'loadApplicant'
        | 'loadInterviewBreak'
        | 'loadInterviewDay'
        | 'loadInterviewRoom'
        | 'loadLetterOfRecommendation'
        | 'loadPdfRenderJob'
        | 'loadProgInstance'
        | 'loadProgTemplate'
        | 'loadProgram'
        | 'loadProgramTeamInvite'
        | 'loadProgramUser'
        | 'loadRecommender'
        | 'loadReviewerAppInstance'
        | 'loadUser'
        | 'loadZipJob'
        | 'loadApplicantScores';
    navigationFields:
        | 'loadAdministrator'
        | 'loadAppAssistDocument'
        | 'loadAppAssister'
        | 'loadAppGroup'
        | 'loadAppInstance'
        | 'loadApplicant'
        | 'loadInterviewBreak'
        | 'loadInterviewDay'
        | 'loadInterviewRoom'
        | 'loadLetterOfRecommendation'
        | 'loadPdfRenderJob'
        | 'loadProgInstance'
        | 'loadProgTemplate'
        | 'loadProgram'
        | 'loadProgramTeamInvite'
        | 'loadProgramUser'
        | 'loadRecommender'
        | 'loadReviewerAppInstance'
        | 'loadUser'
        | 'loadZipJob'
        | 'loadApplicantScores';
};

export type AdminEmailManager = {
    name: 'AdminEmailManager';
    fields: {
        sendEmails: (
            params: S.AdminEmailManager_SendEmailsParamsInput
        ) => Array<AdminEmailManager_SendEmailsResult>;
    };
    mutationFields: 'sendEmails';
};

export type Administrator = {
    name: 'Administrator';
    fields: {
        id: number;
        emailManager: AdminEmailManager;
    };
    queryFields: 'id' | 'emailManager';
    navigationFields: 'emailManager';
    loaderFields: 'id';
};

export type AppAssistDocument = {
    name: 'AppAssistDocument';
    fields: {
        appAssister: AppAssister;
        applicant: Applicant;
        fileApi: FileApi;
        setUploaded: (uploaded: boolean) => boolean;
        id: number;
        type: AppAssistDocumentType;
        uploaded: boolean;
    };
    queryFields:
        | 'appAssister'
        | 'applicant'
        | 'fileApi'
        | 'id'
        | 'type'
        | 'uploaded';
    mutationFields: 'setUploaded';
    navigationFields: 'appAssister' | 'applicant';
    loaderFields: 'id';
};

export type AppAssister = {
    name: 'AppAssister';
    fields: {
        user: User;
        transcripts: Array<AppAssistDocument>;
        mspes: Array<AppAssistDocument>;
        id: number;
    };
    queryFields: 'user' | 'transcripts' | 'mspes' | 'id';
    navigationFields: 'user' | 'transcripts' | 'mspes';
    loaderFields: 'id';
};

export type AppGroup = {
    name: 'AppGroup';
    fields: {
        members: Array<ProgramUser>;
        isOwner: boolean;
        progInstances: Array<ProgInstance>;
        progInstancesIterate: (
            offset: number,
            limit: number
        ) => Array<ProgInstance>;
        numProgInstances: number;
        id: number;
        ownerApi: AppGroupOwnerApi;
        name: string;
        description: string;
    };
    queryFields:
        | 'members'
        | 'isOwner'
        | 'progInstances'
        | 'progInstancesIterate'
        | 'numProgInstances'
        | 'id'
        | 'ownerApi'
        | 'name'
        | 'description';
    navigationFields:
        | 'members'
        | 'progInstances'
        | 'progInstancesIterate'
        | 'ownerApi';
    loaderFields: 'id';
};

export type AppGroupOwnerApi = {
    name: 'AppGroupOwnerApi';
    fields: {
        update: (update: S.AppGroupUpdateInput) => AppGroup;
    };
    mutationFields: 'update';
};

export type AppInstance = {
    name: 'AppInstance';
    fields: {
        appTemplate: AppTemplate;
        applicant: Applicant;
        state: AppInstanceState;
        responses: Array<AppResponse>;
        response: (fieldKey: string) => AppResponse | null;
        lettersOfRecommendation: Array<LetterOfRecommendation>;
        progInstances: Array<ProgInstance>;
        fileApi: (fieldKey: string) => FileApi;
        createProgInstance: (progTemplateID: number) => ProgInstance;
        payment: Payment | null;
        initializePayment: Payment;
        id: number;
        applicantApi: ApplicantAppInstanceApi;
    };
    queryFields:
        | 'appTemplate'
        | 'applicant'
        | 'state'
        | 'responses'
        | 'response'
        | 'lettersOfRecommendation'
        | 'progInstances'
        | 'fileApi'
        | 'payment'
        | 'id'
        | 'applicantApi';
    mutationFields: 'createProgInstance' | 'initializePayment';
    navigationFields:
        | 'appTemplate'
        | 'applicant'
        | 'lettersOfRecommendation'
        | 'progInstances'
        | 'applicantApi';
    loaderFields: 'id';
};

export type AppResponse = {
    name: 'AppResponse';
    fields: {
        getDownloadUrl: string | null;
        fieldKey: string;
        data: string;
    };
    queryFields: 'getDownloadUrl' | 'fieldKey' | 'data';
};

export type AppTemplate = {
    name: 'AppTemplate';
    fields: {
        progTemplates: Array<ProgTemplate>;
        applicantAppInstance: AppInstance | null;
        applicantSchemaJson: string;
        recommenderSchemaJson: string;
        reviewerSchemaJson: string;
        customizeApplicationUI: string;
        facultyTableConfig: string;
        id: number;
        name: string;
        applicantReleaseDate: string;
        applicantDeadline: string;
        programReleaseDate: string;
    };
    queryFields:
        | 'progTemplates'
        | 'applicantAppInstance'
        | 'applicantSchemaJson'
        | 'recommenderSchemaJson'
        | 'reviewerSchemaJson'
        | 'customizeApplicationUI'
        | 'facultyTableConfig'
        | 'id'
        | 'name'
        | 'applicantReleaseDate'
        | 'applicantDeadline'
        | 'programReleaseDate';
    navigationFields: 'progTemplates' | 'applicantAppInstance';
};

export type Applicant = {
    name: 'Applicant';
    fields: {
        user: User;
        documents: Array<AppAssistDocument>;
        scores: ApplicantScores;
        acceptedDocumentDisclosure: boolean;
        acceptDocumentDisclosure: boolean;
        requestDocument: (
            type: AppAssistDocumentType,
            email: string
        ) => AppAssistDocument | null;
        scoreReportFileApi: (reportUuid: string) => FileApi;
        id: number;
    };
    queryFields:
        | 'user'
        | 'documents'
        | 'scores'
        | 'acceptedDocumentDisclosure'
        | 'scoreReportFileApi'
        | 'id';
    mutationFields: 'acceptDocumentDisclosure' | 'requestDocument';
    navigationFields: 'user' | 'documents' | 'scores';
    loaderFields: 'id';
};

export type ApplicationForReview = {
    name: 'ApplicationForReview';
    fields: {
        applicantScores: Array<ApplicantScore>;
        appInstanceResponses: Array<AppResponse>;
        appInstanceResponse: (fieldKey: string) => AppResponse | null;
        signal: ProgInstanceSignal;
        reviewerSchemaJson: string;
        applicantNameParts: ApplicationForReview_ApplicantName;
        applicantName: string | null;
        lettersOfRecommendation: Array<ApplicationForReview_LetterView>;
        documents: Array<ApplicationForReview_DocumentView>;
        id: number;
        signalStatement: string | null;
    };
    queryFields:
        | 'applicantScores'
        | 'appInstanceResponses'
        | 'appInstanceResponse'
        | 'signal'
        | 'reviewerSchemaJson'
        | 'applicantNameParts'
        | 'applicantName'
        | 'lettersOfRecommendation'
        | 'documents'
        | 'id'
        | 'signalStatement';
};

export type EmailVerification = {
    name: 'EmailVerification';
    fields: {
        user: EmailVerificationUserInfo;
        verifyEmail: (password: string | null) => boolean;
        id: number;
        newEmail: string;
    };
    queryFields: 'user' | 'id' | 'newEmail';
    mutationFields: 'verifyEmail';
};

export type InterviewBreak = {
    name: 'InterviewBreak';
    fields: {
        update: (
            startTime: string,
            endTime: string,
            name: string,
            isMeal: boolean
        ) => InterviewBreak;
        delete: InterviewBreak_DeletionResult;
        id: number;
        startTime: string;
        endTime: string;
        name: string;
        isMeal: boolean;
    };
    queryFields: 'id' | 'startTime' | 'endTime' | 'name' | 'isMeal';
    mutationFields: 'update' | 'delete';
    loaderFields: 'id';
};

export type InterviewDay = {
    name: 'InterviewDay';
    fields: {
        interviewBreaks: Array<InterviewBreak>;
        interviewRooms: Array<InterviewRoom>;
        update: (
            startDate: string,
            startTime: string,
            endTime: string,
            interviewLengthInMinutes: number,
            breakTimeInMinutes: number,
            interviewsPerApplicant: number
        ) => InterviewDay;
        createInterviewBreak: (
            name: string,
            startTime: string,
            endTime: string,
            interviewDayID: number
        ) => InterviewBreak;
        createInterviewRoom: (
            name: string,
            interviewDayID: number
        ) => InterviewRoom;
        delete: InterviewDay_DeletionResult;
        id: number;
        startDate: string;
        startTime: string;
        endTime: string;
        interviewLengthInMinutes: number;
        breakTimeInMinutes: number;
        interviewsPerApplicant: number;
    };
    queryFields:
        | 'interviewBreaks'
        | 'interviewRooms'
        | 'id'
        | 'startDate'
        | 'startTime'
        | 'endTime'
        | 'interviewLengthInMinutes'
        | 'breakTimeInMinutes'
        | 'interviewsPerApplicant';
    mutationFields:
        | 'update'
        | 'createInterviewBreak'
        | 'createInterviewRoom'
        | 'delete';
    navigationFields: 'interviewBreaks' | 'interviewRooms';
    loaderFields: 'id';
};

export type InterviewRoom = {
    name: 'InterviewRoom';
    fields: {
        update: (name: string) => InterviewRoom;
        delete: InterviewRoom_DeletionResult;
        id: number;
        name: string;
    };
    queryFields: 'id' | 'name';
    mutationFields: 'update' | 'delete';
    loaderFields: 'id';
};

export type LetterOfRecommendation = {
    name: 'LetterOfRecommendation';
    fields: {
        recommender: Recommender | null;
        appInstance: AppInstance;
        lastRemind: string | null;
        responses: Array<LetterResponse>;
        fileApi: (fieldKey: string) => FileApi;
        applicantUpdateStatus: (
            newStatus: LetterOfRecommendation_ApplicantStatusUpdate
        ) => boolean;
        remind: boolean;
        recommenderRespond: (accept: boolean) => boolean;
        updateResponses: (payload: string) => boolean;
        recommenderUpdateStatus: (submitted: boolean) => boolean;
        id: number;
        status: LetterOfRecommendationStatus;
    };
    queryFields:
        | 'recommender'
        | 'appInstance'
        | 'lastRemind'
        | 'responses'
        | 'fileApi'
        | 'id'
        | 'status';
    mutationFields:
        | 'applicantUpdateStatus'
        | 'remind'
        | 'recommenderRespond'
        | 'updateResponses'
        | 'recommenderUpdateStatus';
    navigationFields: 'recommender' | 'appInstance';
    loaderFields: 'id';
};

export type LetterResponse = {
    name: 'LetterResponse';
    fields: {
        fieldKey: string;
        data: string;
    };
    queryFields: 'fieldKey' | 'data';
};

export type Payment = {
    name: 'Payment';
    fields: {
        clientSecret: string | null;
        status: StripePaymentStatus;
        lastPaymentError: string | null;
        stripePublicKey: string;
        id: number;
        waived: boolean;
    };
    queryFields:
        | 'clientSecret'
        | 'status'
        | 'lastPaymentError'
        | 'stripePublicKey'
        | 'id'
        | 'waived';
};

export type PdfRenderJob = {
    name: 'PdfRenderJob';
    fields: {
        tryDownload: string | null;
        id: number;
    };
    queryFields: 'id';
    mutationFields: 'tryDownload';
    loaderFields: 'id';
};

export type PendingAccountLink = {
    name: 'PendingAccountLink';
    fields: {
        user: User;
        bypassLoginAccessToken: string | null;
        confirmAccount: (
            data: S.RegistrationDataInput
        ) => PendingAccountLink_ConfirmAccountResult;
        id: number;
    };
    queryFields: 'user' | 'bypassLoginAccessToken' | 'id';
    mutationFields: 'confirmAccount';
    navigationFields: 'user';
};

export type ProgInstance = {
    name: 'ProgInstance';
    fields: {
        signal: ProgInstanceSignal;
        programComment: string;
        progTemplate: ProgTemplate;
        applicationForReview: ApplicationForReview;
        assignedToReview: boolean;
        reviewState: ReviewerAppInstanceState | null;
        reviewerAppInstance: ReviewerAppInstance | null;
        completedReviews: Array<ReviewerAppInstance>;
        responses: Array<ProgInstanceResponse>;
        delete: boolean;
        updateSignalStatement: (
            signalStatement: string | null,
            signalDataUpdate: S.ProgInstance_SignalDataUpdateInput | null
        ) => boolean;
        updateResponses: (payload: string) => boolean;
        tryDownloadPdf: ProgInstancePdfDownloadResult;
        setOpened: boolean;
        id: number;
        signalStatement: string | null;
    };
    queryFields:
        | 'signal'
        | 'programComment'
        | 'progTemplate'
        | 'applicationForReview'
        | 'assignedToReview'
        | 'reviewState'
        | 'reviewerAppInstance'
        | 'completedReviews'
        | 'responses'
        | 'setOpened'
        | 'id'
        | 'signalStatement';
    mutationFields:
        | 'delete'
        | 'updateSignalStatement'
        | 'updateResponses'
        | 'tryDownloadPdf';
    navigationFields:
        | 'progTemplate'
        | 'reviewerAppInstance'
        | 'completedReviews';
    loaderFields: 'id';
};

export type ProgInstanceResponse = {
    name: 'ProgInstanceResponse';
    fields: {
        fieldKey: string;
        data: string;
    };
    queryFields: 'fieldKey' | 'data';
};

export type ProgInstanceSignal = {
    name: 'ProgInstanceSignal';
    fields: {
        signaled: boolean;
        statement: string;
        type: ProgInstanceSignal_Type;
    };
    queryFields: 'signaled' | 'statement' | 'type';
};

export type ProgTemplate = {
    name: 'ProgTemplate';
    fields: {
        appTemplate: AppTemplate;
        program: Program;
        progInstancesIterate: (offset: number) => Array<ProgInstance>;
        progInstancesOffsets: Array<number>;
        allProgInstances: Array<ProgTemplate_SelectApplicationView>;
        hiddenFields: Array<string>;
        hiddenTasks: Array<string>;
        rubricUI: string;
        setRubricUI: (rubricUI: string) => boolean;
        bulkAssignApplications: (
            entries: Array<S.ProgTemplate_AssignEntryInput>
        ) => boolean;
        interviewDays: Array<InterviewDay>;
        createInterviewDay: (startDate: string) => InterviewDay;
        id: number;
        customizeApplicationApi: PMCustomizeApplicationApi;
    };
    queryFields:
        | 'appTemplate'
        | 'program'
        | 'progInstancesIterate'
        | 'progInstancesOffsets'
        | 'allProgInstances'
        | 'hiddenFields'
        | 'hiddenTasks'
        | 'rubricUI'
        | 'interviewDays'
        | 'id'
        | 'customizeApplicationApi';
    mutationFields:
        | 'setRubricUI'
        | 'bulkAssignApplications'
        | 'createInterviewDay';
    navigationFields:
        | 'appTemplate'
        | 'program'
        | 'progInstancesIterate'
        | 'interviewDays'
        | 'customizeApplicationApi';
    loaderFields: 'id';
};

export type Program = {
    name: 'Program';
    fields: {
        programTeam: ProgramTeam;
        currentProgTemplate: ProgTemplate;
        id: number;
        name: string;
        status: ProgramStatus;
        usingStatus: ProgramUsing;
        link: string | null;
    };
    queryFields:
        | 'programTeam'
        | 'currentProgTemplate'
        | 'id'
        | 'name'
        | 'status'
        | 'usingStatus'
        | 'link';
    navigationFields: 'programTeam' | 'currentProgTemplate';
    loaderFields: 'id';
};

export type ProgramTeam = {
    name: 'ProgramTeam';
    fields: {
        members: Array<ProgramUser>;
        invites: Array<ProgramTeamInvite>;
        numInvites: number;
        inviteMember: (
            email: string,
            roles: Array<UserRole>
        ) => InviteMemberResult;
        programID: number;
    };
    queryFields: 'members' | 'invites' | 'numInvites' | 'programID';
    mutationFields: 'inviteMember';
    navigationFields: 'members' | 'invites';
};

export type ProgramManager = {
    name: 'ProgramManager';
    fields: {
        program: Program;
        id: number;
        variant: ProgramManagerVariant;
    };
    queryFields: 'program' | 'id' | 'variant';
    navigationFields: 'program';
};

export type ProgramTeamInvite = {
    name: 'ProgramTeamInvite';
    fields: {
        name: string;
        email: string;
        roles: Array<UserRole>;
        cancel: boolean;
        id: number;
    };
    queryFields: 'name' | 'email' | 'roles' | 'id';
    mutationFields: 'cancel';
    loaderFields: 'id';
};

export type ProgramUser = {
    name: 'ProgramUser';
    fields: {
        reviewer: Reviewer | null;
        programManager: ProgramManager | null;
        user: User;
        program: Program;
        roles: Array<UserRole>;
        name: string;
        email: string;
        groups: Array<AppGroup>;
        group: (id: number) => AppGroup;
        openedProgInstanceIDs: Array<number>;
        createGroup: (info: S.AppGroupUpdateInput) => AppGroup;
        programManagerUpdateRoles: (roles: Array<UserRole>) => boolean;
        updateProgInstanceGroups: (
            add: Array<S.ProgramUser_ProgInstanceGroupUpdateInput>,
            remove: Array<S.ProgramUser_ProgInstanceGroupUpdateInput>
        ) => boolean;
        startZipJob: ZipJob;
        downloadCSV: (progInstanceIDs: Array<number>) => string;
        id: number;
    };
    queryFields:
        | 'reviewer'
        | 'programManager'
        | 'user'
        | 'program'
        | 'roles'
        | 'name'
        | 'email'
        | 'groups'
        | 'group'
        | 'openedProgInstanceIDs'
        | 'downloadCSV'
        | 'id';
    mutationFields:
        | 'createGroup'
        | 'programManagerUpdateRoles'
        | 'updateProgInstanceGroups'
        | 'startZipJob';
    navigationFields:
        | 'reviewer'
        | 'programManager'
        | 'user'
        | 'program'
        | 'groups'
        | 'group';
    loaderFields: 'id';
};

export type Recommender = {
    name: 'Recommender';
    fields: {
        user: User;
        lettersOfRecommendation: Array<LetterOfRecommendation>;
        verified: boolean;
        id: number;
    };
    queryFields: 'user' | 'lettersOfRecommendation' | 'verified' | 'id';
    navigationFields: 'user' | 'lettersOfRecommendation';
    loaderFields: 'id';
};

export type Reviewer = {
    name: 'Reviewer';
    fields: {
        reviewerAppInstances: Array<ReviewerAppInstance>;
        unassignAll: boolean;
        id: number;
    };
    queryFields: 'reviewerAppInstances' | 'id';
    mutationFields: 'unassignAll';
    navigationFields: 'reviewerAppInstances';
};

export type ReviewerAppInstance = {
    name: 'ReviewerAppInstance';
    fields: {
        reviewer: Reviewer | null;
        rubricResponses: Array<RubricResponse>;
        applicantInfo: ReviewerAppInstance_ApplicantInfo;
        reviewerInfo: ReviewerAppInstance_ReviewerInfo;
        progInstance: ProgInstance | null;
        updateResponses: (payload: string) => boolean;
        updateState: (newState: ReviewerAppInstanceState) => boolean;
        unassign: boolean;
        id: number;
        state: ReviewerAppInstanceState;
    };
    queryFields:
        | 'reviewer'
        | 'rubricResponses'
        | 'applicantInfo'
        | 'reviewerInfo'
        | 'progInstance'
        | 'id'
        | 'state';
    mutationFields: 'updateResponses' | 'updateState' | 'unassign';
    navigationFields: 'reviewer' | 'progInstance';
    loaderFields: 'id';
};

export type RubricResponse = {
    name: 'RubricResponse';
    fields: {
        fieldKey: string;
        data: string;
    };
    queryFields: 'fieldKey' | 'data';
};

export type Specialty = {
    name: 'Specialty';
    fields: {
        name: string;
        latestAppTemplate: AppTemplate | null;
        id: number;
    };
    queryFields: 'name' | 'latestAppTemplate' | 'id';
    navigationFields: 'latestAppTemplate';
};

export type AutoPopApi = {
    name: 'AutoPopApi';
    fields: {
        shownPopKeys: Array<string>;
        setPopKeyShown: (popKey: string, show: boolean) => boolean;
    };
    queryFields: 'shownPopKeys';
    mutationFields: 'setPopKeyShown';
};

export type User = {
    name: 'User';
    fields: {
        accountAccessApi: AccountAccessApi | null;
        programUser: ProgramUser | null;
        recommender: Recommender | null;
        applicant: Applicant | null;
        appAssister: AppAssister | null;
        administrator: Administrator | null;
        name: string;
        phone: string | null;
        acceptedTos: boolean;
        photoUploaded: boolean;
        fileApi: FileApi;
        emailVerification: EmailVerification | null;
        acceptTermsOfService: (version: number) => boolean;
        setPhotoUploaded: (photoUploaded: boolean) => boolean;
        updateUserInfo: (update: S.UserUpdateInfoInput) => boolean;
        getAddSMSToken: (phone: string) => string | null;
        updatePassword: (
            currentPassword: string,
            newPassword: string
        ) => boolean;
        id: number;
        autoPopApi: AutoPopApi;
        easyDataApi: UserEasyDataApi;
        firstName: string;
        lastName: string;
        email: string;
        institution: string;
    };
    queryFields:
        | 'accountAccessApi'
        | 'programUser'
        | 'recommender'
        | 'applicant'
        | 'appAssister'
        | 'administrator'
        | 'name'
        | 'phone'
        | 'acceptedTos'
        | 'photoUploaded'
        | 'fileApi'
        | 'emailVerification'
        | 'id'
        | 'autoPopApi'
        | 'easyDataApi'
        | 'firstName'
        | 'lastName'
        | 'email'
        | 'institution';
    mutationFields:
        | 'acceptTermsOfService'
        | 'setPhotoUploaded'
        | 'updateUserInfo'
        | 'getAddSMSToken'
        | 'updatePassword';
    navigationFields:
        | 'accountAccessApi'
        | 'programUser'
        | 'recommender'
        | 'applicant'
        | 'appAssister'
        | 'administrator'
        | 'emailVerification'
        | 'autoPopApi'
        | 'easyDataApi';
    loaderFields: 'id';
};

export type UserEasyDataApi = {
    name: 'UserEasyDataApi';
    fields: {
        get: (dataKey: string) => string | null;
        set: (dataKey: string, data: string | null) => boolean;
    };
    queryFields: 'get';
    mutationFields: 'set';
};

export type ZipJob = {
    name: 'ZipJob';
    fields: {
        addProgInstances: (progInstanceIDs: Array<number>) => boolean;
        tryDownloadZip: (fileName: string) => string | null;
        id: number;
    };
    queryFields: 'tryDownloadZip' | 'id';
    mutationFields: 'addProgInstances';
    loaderFields: 'id';
};

export type Root = {
    name: 'Root';
    fields: {
        loader: SuperQLLoader;
        publicApi: PublicApi;
        anyUserApi: AnyUserApi;
        programUserApi: ProgramUserApi;
        applicantApi: ApplicantApi;
        globalSettings: GlobalSettings;
        frequentQuestionApi: FrequentQuestionApi;
    };
    queryFields:
        | 'loader'
        | 'publicApi'
        | 'anyUserApi'
        | 'programUserApi'
        | 'applicantApi'
        | 'globalSettings'
        | 'frequentQuestionApi';
    navigationFields:
        | 'loader'
        | 'publicApi'
        | 'anyUserApi'
        | 'programUserApi'
        | 'applicantApi';
};

export type ApplicantScore = {
    name: 'ApplicantScore';
    fields: {
        value: string;
        verified: boolean;
        type: ApplicantScoreType;
    };
    queryFields: 'value' | 'verified' | 'type';
};

export type ApplicantScores = {
    name: 'ApplicantScores';
    fields: {
        scores: Array<ApplicantScore>;
        verificationComplete: boolean;
        update: (updates: Array<S.ApplicantScoreUpdateInput>) => boolean;
        scoreReports: Array<ApplicantScoreReport>;
        updateScoreReports: (
            scoreReports: Array<S.ApplicantScoreReportInput>
        ) => boolean;
        verifyScore: (
            type: ApplicantScoreType,
            verifiedValue: string | null
        ) => boolean;
        id: number;
    };
    queryFields: 'scores' | 'verificationComplete' | 'scoreReports' | 'id';
    mutationFields: 'update' | 'updateScoreReports' | 'verifyScore';
    loaderFields: 'id';
};

export type AccountAccessor = {
    name: 'AccountAccessor';
    fields: {
        id: number;
        name: string;
        email: string;
        institution: string;
    };
    queryFields: 'id' | 'name' | 'email' | 'institution';
};

export type InformationUpdate = {
    name: 'InformationUpdate';
    fields: {
        firstName: string | null;
        lastName: string | null;
        institution: string | null;
    };
    queryFields: 'firstName' | 'lastName' | 'institution';
};

export type FrequentQuestion = {
    name: 'FrequentQuestion';
    fields: {
        question: string;
        answer: string;
        format: FrequentQuestionFormat;
        testing: boolean;
    };
    queryFields: 'question' | 'answer' | 'format' | 'testing';
};

export type RequestExternalRecommendationParam = {
    name: 'RequestExternalRecommendationParam';
    fields: {
        firstName: string;
        lastName: string;
        institution: string;
        email: string;
    };
    queryFields: 'firstName' | 'lastName' | 'institution' | 'email';
};

export type RequestExternalRecommendationResult = {
    name: 'RequestExternalRecommendationResult';
    fields: {
        success: LetterOfRecommendation | null;
        fail: boolean;
    };
    queryFields: 'success' | 'fail';
    navigationFields: 'success';
};

export type SendHelpRequestParams = {
    name: 'SendHelpRequestParams';
    fields: {
        name: string;
        email: string;
        phone: string;
        message: string;
    };
    queryFields: 'name' | 'email' | 'phone' | 'message';
};

export type TermsOfServiceContent = {
    name: 'TermsOfServiceContent';
    fields: {
        version: number;
        html: string;
    };
    queryFields: 'version' | 'html';
};

export type LoginSuccessResponse = {
    name: 'LoginSuccessResponse';
    fields: {
        accessToken: string | null;
        verifyEmailToken: string | null;
    };
    queryFields: 'accessToken' | 'verifyEmailToken';
};

export type RegisterParam = {
    name: 'RegisterParam';
    fields: {
        data: RegistrationData;
    };
    queryFields: 'data';
};

export type UserAuthMFAToken = {
    name: 'UserAuthMFAToken';
    fields: {
        token: string;
        message: string | null;
        mfaSetup: boolean;
    };
    queryFields: 'token' | 'message' | 'mfaSetup';
};

export type RegisterResponse = {
    name: 'RegisterResponse';
    fields: {
        emailTaken: boolean | null;
        mfaToken: string | null;
    };
    queryFields: 'emailTaken' | 'mfaToken';
};

export type AdminEmailManager_EmailConfig = {
    name: 'AdminEmailManager_EmailConfig';
    fields: {
        email: string;
        variablesJSON: string;
    };
    queryFields: 'email' | 'variablesJSON';
};

export type AdminEmailManager_SendEmailsParams = {
    name: 'AdminEmailManager_SendEmailsParams';
    fields: {
        template: string;
        subject: string;
        configs: Array<AdminEmailManager_EmailConfig>;
    };
    queryFields: 'template' | 'subject' | 'configs';
};

export type AdminEmailManager_SendEmailsResult = {
    name: 'AdminEmailManager_SendEmailsResult';
    fields: {
        config: AdminEmailManager_EmailConfig;
        error: string | null;
    };
    queryFields: 'config' | 'error';
};

export type Administrator_ProgramUserToFix = {
    name: 'Administrator_ProgramUserToFix';
    fields: {
        id: number;
        email: string;
    };
    queryFields: 'id' | 'email';
};

export type AppGroupUpdate = {
    name: 'AppGroupUpdate';
    fields: {
        name: string;
        description: string;
        memberIDs: Array<number>;
    };
    queryFields: 'name' | 'description' | 'memberIDs';
};

export type ApplicationForReview_PlasticForm = {
    name: 'ApplicationForReview_PlasticForm';
    fields: {
        presentPosition: string;
        phone: string;
        email: string;
        howLongKnown: string;
        natureOfContact: string;
        scaleOverall: string;
        scaleWorkEthic: string;
        scaleOperativeSkill: string;
        scaleSelfInitiative: string;
        scaleCommunicationSkills: string;
        scaleAcademicSkills: string;
        scaleTeamPlayer: string;
        scalePatientCare: string;
        scaleResearchAndTeaching: string;
        scaleProfessionalism: string;
        strongPoint: string;
        improve: string;
        rank: string;
        willingToDiscuss: string;
        comments: string;
    };
    queryFields:
        | 'presentPosition'
        | 'phone'
        | 'email'
        | 'howLongKnown'
        | 'natureOfContact'
        | 'scaleOverall'
        | 'scaleWorkEthic'
        | 'scaleOperativeSkill'
        | 'scaleSelfInitiative'
        | 'scaleCommunicationSkills'
        | 'scaleAcademicSkills'
        | 'scaleTeamPlayer'
        | 'scalePatientCare'
        | 'scaleResearchAndTeaching'
        | 'scaleProfessionalism'
        | 'strongPoint'
        | 'improve'
        | 'rank'
        | 'willingToDiscuss'
        | 'comments';
};

export type ApplicationForReview_LetterView = {
    name: 'ApplicationForReview_LetterView';
    fields: {
        recommenderName: string;
        recommenderInstitution: string;
        url: string | null;
        form: ApplicationForReview_PlasticForm | null;
    };
    queryFields: 'recommenderName' | 'recommenderInstitution' | 'url' | 'form';
};

export type ApplicationForReview_DocumentView = {
    name: 'ApplicationForReview_DocumentView';
    fields: {
        type: AppAssistDocumentType;
        url: string | null;
        messageIfMissing: string;
    };
    queryFields: 'type' | 'url' | 'messageIfMissing';
};

export type ApplicationForReview_ApplicantName = {
    name: 'ApplicationForReview_ApplicantName';
    fields: {
        firstName: string | null;
        lastName: string | null;
    };
    queryFields: 'firstName' | 'lastName';
};

export type EmailVerificationUserInfo = {
    name: 'EmailVerificationUserInfo';
    fields: {
        id: number;
        email: string;
    };
    queryFields: 'id' | 'email';
};

export type InterviewBreak_DeletionResult = {
    name: 'InterviewBreak_DeletionResult';
    fields: {
        id: number;
    };
    queryFields: 'id';
};

export type InterviewDay_DeletionResult = {
    name: 'InterviewDay_DeletionResult';
    fields: {
        id: number;
    };
    queryFields: 'id';
};

export type InterviewRoom_DeletionResult = {
    name: 'InterviewRoom_DeletionResult';
    fields: {
        id: number;
    };
    queryFields: 'id';
};

export type PendingAccountLink_ConfirmAccountResult = {
    name: 'PendingAccountLink_ConfirmAccountResult';
    fields: {
        mfaToken: UserAuthMFAToken | null;
        emailTaken: boolean;
    };
    queryFields: 'mfaToken' | 'emailTaken';
};

export type ProgInstance_SignalDataUpdate = {
    name: 'ProgInstance_SignalDataUpdate';
    fields: {
        signalData: string | null;
    };
    queryFields: 'signalData';
};

export type ProgTemplate_SelectApplicationView = {
    name: 'ProgTemplate_SelectApplicationView';
    fields: {
        id: number;
        name: string;
    };
    queryFields: 'id' | 'name';
};

export type ProgTemplate_AssignEntry = {
    name: 'ProgTemplate_AssignEntry';
    fields: {
        progInstanceID: number;
        programUserID: number;
    };
    queryFields: 'progInstanceID' | 'programUserID';
};

export type InviteMemberResult = {
    name: 'InviteMemberResult';
    fields: {
        invalid: boolean;
        autoJoin: InviteMemberResult_AutoJoin | null;
        success: ProgramTeamInvite | null;
    };
    queryFields: 'invalid' | 'autoJoin' | 'success';
    navigationFields: 'success';
};

export type InviteMemberResult_AutoJoin = {
    name: 'InviteMemberResult_AutoJoin';
    fields: {
        id: number;
        name: string;
        email: string;
    };
    queryFields: 'id' | 'name' | 'email';
};

export type ProgramUser_ProgInstanceGroupUpdate = {
    name: 'ProgramUser_ProgInstanceGroupUpdate';
    fields: {
        groupID: number;
        progInstanceID: number;
    };
    queryFields: 'groupID' | 'progInstanceID';
};

export type ReviewerAppInstance_ApplicantInfo = {
    name: 'ReviewerAppInstance_ApplicantInfo';
    fields: {
        firstName: string;
        lastName: string;
    };
    queryFields: 'firstName' | 'lastName';
};

export type ReviewerAppInstance_ReviewerInfo = {
    name: 'ReviewerAppInstance_ReviewerInfo';
    fields: {
        id: number;
        firstName: string;
        lastName: string;
    };
    queryFields: 'id' | 'firstName' | 'lastName';
};

export type UserUpdateInfo = {
    name: 'UserUpdateInfo';
    fields: {
        firstName: string | null;
        lastName: string | null;
        email: string | null;
        institution: string | null;
    };
    queryFields: 'firstName' | 'lastName' | 'email' | 'institution';
};

export type ApplicantScoreUpdate = {
    name: 'ApplicantScoreUpdate';
    fields: {
        type: ApplicantScoreType;
        value: string;
    };
    queryFields: 'type' | 'value';
};

export type ApplicantScoreReport = {
    name: 'ApplicantScoreReport';
    fields: {
        type: ApplicantScoreType;
        reportUUID: string;
    };
    queryFields: 'type' | 'reportUUID';
};

export type ProgInstancePdfDownloadResult = {
    name: 'ProgInstancePdfDownloadResult';
    fields: {
        url: string | null;
        job: PdfRenderJob | null;
    };
    queryFields: 'url' | 'job';
    navigationFields: 'job';
};

export type ProgramUserZipDownloadResult = {
    name: 'ProgramUserZipDownloadResult';
    fields: {
        url: string | null;
        job: ZipJob | null;
    };
    queryFields: 'url' | 'job';
    navigationFields: 'job';
};

export type RegistrationData = {
    name: 'RegistrationData';
    fields: {
        email: string;
        password: string;
        firstName: string;
        lastName: string;
        institution: string;
    };
    queryFields:
        | 'email'
        | 'password'
        | 'firstName'
        | 'lastName'
        | 'institution';
};
