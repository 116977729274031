import { useEffect, useRef } from 'react';
import { executeAsync } from '../util/util';

export function useInit(cb: () => void | Promise<void>) {
    const ref = useRef<boolean>(false);
    useEffect(
        () =>
            executeAsync(async () => {
                if (ref.current) return;
                ref.current = true;
                await cb();
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
}
