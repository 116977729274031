import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { D1SpinnerBrain } from '../../components/design/spinner/d1spinnerBrain';
import { RouteBuilder } from '../../generated/router/routeBuilder';
import { TGlobalSettings } from '../../generated/syncShared/globalSettingsType';
import { ACCESS_TOKEN_EXPIRY_SECS } from '../../generated/syncShared/syncShared';
import { useInit } from '../../hooks/useInit';
import { ChildrenProps } from '../../types/generalTypes';
import { SuperObjectWithID } from '../../util/superqlUtil';
import { Util } from '../../util/util';
import { SET_ROOT_MESSAGE } from '../rootErrorContext';
import {
    executeQueryFrontendAuthInfo,
    FrontendAuthInfoUser,
} from './authContext.query';
import { executeQueryAuthContext_RootPageLoad } from './authContextRootPageLoad.query';

type FrontendAuthInfoObj = FrontendAuthInfoUser['o'];

export type AuthStateLoggedIn = {
    type: 'loggedIn';
    accessTokenDONOTUSE: string;
    frontendAuthInfo: FrontendAuthInfoObj;
};

export type AuthState =
    | {
          type: 'loggedOut';
      }
    | AuthStateLoggedIn;

type TAuthContext = {
    state: AuthState;
    globalSettings: TGlobalSettings;
    setState: (state: AuthState) => void;
    onLogin: (accessToken: string) => Promise<void>;
    onLogout: () => void;
};

const AuthContext = Util.createContext<TAuthContext>();

export class AccessTokenProvider {
    private static expiryTime: number = 0;

    private static _authState: AuthState = { type: 'loggedOut' };

    public static setReactState: (authState: AuthState) => void;
    public static setSettingsReactState: (
        globalSettings: TGlobalSettings
    ) => void;

    static get authState(): AuthState {
        return this._authState;
    }

    static set authState(authState: AuthState) {
        this._authState = authState;
        if (authState.type === 'loggedOut') {
            this.expiryTime = 0;
        } else {
            this.expiryTime =
                Util.now() + (ACCESS_TOKEN_EXPIRY_SECS - 60) * 1000;
        }
        this.setReactState(authState);
    }

    public static async setAccessToken(accessToken: string) {
        // temp auth state to allow executeQueryFrontendAuthInfo to succeed
        // Set _authState to not trigger react update
        this._authState = {
            type: 'loggedIn',
            accessTokenDONOTUSE: accessToken,
            frontendAuthInfo: null as any,
        };
        this.expiryTime = Util.now() + 5 * 1000;
        const result = await executeQueryFrontendAuthInfo();
        this.authState = {
            type: 'loggedIn',
            accessTokenDONOTUSE: accessToken,
            frontendAuthInfo: result.anyUserApi.self,
        };
    }

    public static async getAccessToken(): Promise<string | null> {
        if (this.authState.type !== 'loggedIn') {
            return null;
        }
        if (Util.now() < this.expiryTime) {
            return this.authState.accessTokenDONOTUSE;
        } else {
            // Refresh call will update auth state again
            return await this.refreshOrReuse();
        }
    }

    // Will trigger a refresh, or if there's an ongoing refresh will just wait
    // and return the result of the ongoing refresh.
    private static refreshOrReuse(): Promise<string> {
        return new Promise((resolve, reject) => {
            if (this.refreshResolvers !== null) {
                this.refreshResolvers.push(resolve);
            } else {
                this.refresh().then(resolve).catch(reject);
            }
        });
    }

    private static refreshResolvers: Array<(token: string) => void> | null =
        null;

    private static async refresh(): Promise<string> {
        this.refreshResolvers = [];
        try {
            const result = await executeQueryAuthContext_RootPageLoad();
            this.setSettingsReactState(
                JSON.parse(result.globalSettings.globalSettingsJson)
            );
            const accessToken = result.publicApi.userAuthApi.refresh;
            if (accessToken === null) {
                SET_ROOT_MESSAGE(
                    'Sorry, your session has expired. Please log in again'
                );
                return '';
            } else {
                await this.setAccessToken(accessToken);
                for (const resolver of this.refreshResolvers) {
                    resolver(accessToken);
                }
                return accessToken;
            }
        } finally {
            this.refreshResolvers = null;
        }
    }
}

export function ProvideAuthContext({ children }: ChildrenProps) {
    const [state, setState] = useState<AuthState | null>(null);
    const [globalSettings, setGlobalSettings] =
        useState<TGlobalSettings | null>(null);
    const value: TAuthContext | null = useMemo(
        () =>
            state === null || globalSettings === null
                ? null
                : {
                      state,
                      globalSettings,
                      setState,
                      onLogin: async (accessToken) =>
                          await AccessTokenProvider.setAccessToken(accessToken),
                      onLogout: () => {
                          AccessTokenProvider.authState = { type: 'loggedOut' };
                      },
                  },
        [globalSettings, state]
    );
    useInit(async () => {
        AccessTokenProvider.setReactState = setState;
        AccessTokenProvider.setSettingsReactState = setGlobalSettings;
        // First time refresh on page load
        const result = await executeQueryAuthContext_RootPageLoad();
        setGlobalSettings(JSON.parse(result.globalSettings.globalSettingsJson));
        const accessToken = result.publicApi.userAuthApi.refresh;
        if (accessToken === null) {
            AccessTokenProvider.authState = { type: 'loggedOut' };
        } else {
            await AccessTokenProvider.setAccessToken(accessToken);
        }
    });
    if (value === null) {
        return <D1SpinnerBrain />;
    }
    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
}

export function useAuthContext(): TAuthContext {
    return useContext(AuthContext);
}

export function useGlobalSettings(): TGlobalSettings {
    return useContext(AuthContext).globalSettings;
}

export function useAuthContextLoggedIn(): {
    state: AuthStateLoggedIn;
    setState: (state: AuthState) => void;
} {
    const { state, setState } = useAuthContext();
    if (state.type !== 'loggedIn') {
        throw new Error('must be logged in');
    }
    return { state, setState };
}

export function useFrontendAuthInfo(): FrontendAuthInfoObj {
    const ctx = useAuthContext();
    if (ctx.state.type !== 'loggedIn') {
        throw new Error('Can only be called from a logged in context');
    }
    return ctx.state.frontendAuthInfo;
}

export function useFrontendAuthInfo_ProgramUser(): Exclude<
    FrontendAuthInfoObj['programUser'],
    null
> {
    const authInfo = useFrontendAuthInfo();
    if (authInfo.programUser === null) {
        throw new Error('No program user');
    }
    return authInfo.programUser;
}

export function useFrontendAuthInfo_Recommender(): Exclude<
    FrontendAuthInfoObj['recommender'],
    null
> {
    const authInfo = useFrontendAuthInfo();
    if (authInfo.recommender === null) {
        throw new Error('No recommender');
    }
    return authInfo.recommender;
}

export function useFrontendAuthInfo_Applicant(): Exclude<
    FrontendAuthInfoObj['applicant'],
    null
> {
    const authInfo = useFrontendAuthInfo();
    if (authInfo.applicant === null) {
        throw new Error('No applicant');
    }
    return authInfo.applicant;
}

export function useFrontendAuthInfo_AppAssister(): SuperObjectWithID<'AppAssister'> {
    const authInfo = useFrontendAuthInfo();
    if (authInfo.appAssister === null) {
        throw new Error('No app assister');
    }
    return authInfo.appAssister;
}

export function useFrontendAuthInfo_Administrator(): SuperObjectWithID<'Administrator'> {
    const authInfo = useFrontendAuthInfo();
    if (authInfo.administrator === null) {
        throw new Error('No administrator');
    }
    return authInfo.administrator;
}

/**
 * Logged out -> SUCCESS
 * Applicant -> applicantHome
 * Program User | Recommender -> facultyHome
 * Else -> userNoProfiles
 */
export function WithAuthNone({ children }: ChildrenProps) {
    const { state } = useAuthContext();
    const navigate = useNavigate();
    useEffect(() => {
        if (state.type !== 'loggedOut') {
            let route;
            if (state.frontendAuthInfo.applicant != null) {
                route = RouteBuilder.applicantHome();
            } else if (
                state.frontendAuthInfo.programUser != null ||
                state.frontendAuthInfo.recommender != null
            ) {
                route = RouteBuilder.facultyHome();
            } else if (state.frontendAuthInfo.appAssister != null) {
                route = RouteBuilder.appAssisterHome();
            } else if (state.frontendAuthInfo.administrator != null) {
                route = RouteBuilder.administrator();
            } else {
                route = RouteBuilder.userNoProfiles();
            }
            navigate(route);
        }
    }, [state, navigate]);
    if (state.type === 'loggedOut') {
        return <>{children}</>;
    } else {
        return null;
    }
}

type AuthAssertHelperProps = ChildrenProps & {
    isValidAuth: (authInfo: FrontendAuthInfoObj) => boolean;
    termsOfService?: 'allow-not-accepted' | 'require-not-accepted';
    bypassApplicantDocuments?: boolean;
};

type AuthAssertHelperPropsExternal = Omit<AuthAssertHelperProps, 'isValidAuth'>;

function AuthAssertHelper({
    children,
    isValidAuth,
    termsOfService,
    bypassApplicantDocuments,
}: AuthAssertHelperProps) {
    const { state } = useAuthContext();
    const navigate = useNavigate();
    const redirect = useMemo(() => {
        if (state.type !== 'loggedIn' || !isValidAuth(state.frontendAuthInfo)) {
            return RouteBuilder.root();
        }
        if (termsOfService === 'require-not-accepted') {
            if (state.frontendAuthInfo.acceptedTos) {
                return RouteBuilder.root();
            } else {
                return null;
            }
        }
        if (
            !state.frontendAuthInfo.acceptedTos &&
            termsOfService !== 'allow-not-accepted' &&
            state.frontendAuthInfo.accountAccessApi?.hasSourceUser !== true
        ) {
            return RouteBuilder.acceptTermsOfService();
        }
        // TEMPORARY for 2023 cycle, TODO remove
        if (
            state.frontendAuthInfo.applicant !== null &&
            !state.frontendAuthInfo.applicant.acceptedDocumentDisclosure &&
            !bypassApplicantDocuments
        ) {
            return RouteBuilder.applicantDocuments();
        }
        return null;
    }, [termsOfService, isValidAuth, state, bypassApplicantDocuments]);
    useEffect(() => {
        if (redirect !== null) {
            navigate(redirect);
        }
    }, [navigate, redirect]);
    if (redirect === null) {
        return <>{children}</>;
    } else {
        return null;
    }
}

/**
 * Logged out -> root
 * No TOS -> acceptTermsOfService
 * Else -> SUCCESS
 */
export function WithAuthAny(props: AuthAssertHelperPropsExternal) {
    return (
        <AuthAssertHelper
            isValidAuth={useCallback(() => true, [])}
            {...props}
        />
    );
}

/**
 * Logged out | Any profile -> root
 * No TOS -> acceptTermsOfService
 * Else -> SUCCESS
 */
export function WithAuthNoProfiles({ children }: ChildrenProps) {
    return (
        <AuthAssertHelper
            isValidAuth={useCallback(
                (authInfo) =>
                    authInfo.applicant == null &&
                    authInfo.programUser == null &&
                    authInfo.recommender == null &&
                    authInfo.appAssister == null &&
                    authInfo.administrator == null,
                []
            )}
        >
            {children}
        </AuthAssertHelper>
    );
}

/**
 * Logged out | !Applicant -> root
 * No TOS -> acceptTermsOfService
 * Else -> SUCCESS
 */
export function WithAuthApplicant(props: AuthAssertHelperPropsExternal) {
    return (
        <AuthAssertHelper
            isValidAuth={useCallback(
                (authInfo) => authInfo.applicant != null,
                []
            )}
            {...props}
        />
    );
}

/**
 * Faculty = Program User || Recommender
 *
 * Logged out | !Faculty -> root
 * No TOS -> acceptTermsOfService
 * Else -> SUCCESS
 */
export function WithAuthFaculty({ children }: ChildrenProps) {
    return (
        <AuthAssertHelper
            isValidAuth={useCallback(
                (authInfo) =>
                    authInfo.programUser != null ||
                    authInfo.recommender != null,
                []
            )}
        >
            {children}
        </AuthAssertHelper>
    );
}

export function WithAuthProgramManager({ children }: ChildrenProps) {
    return (
        <AuthAssertHelper
            isValidAuth={useCallback(
                (authInfo) => authInfo.programUser?.programManager != null,
                []
            )}
        >
            {children}
        </AuthAssertHelper>
    );
}

export function WithAuthProgramUser({ children }: ChildrenProps) {
    return (
        <AuthAssertHelper
            isValidAuth={useCallback(
                (authInfo) => authInfo.programUser != null,
                []
            )}
        >
            {children}
        </AuthAssertHelper>
    );
}

export function WithAuthRecommender({ children }: ChildrenProps) {
    return (
        <AuthAssertHelper
            isValidAuth={useCallback(
                (authInfo) => authInfo.recommender != null,
                []
            )}
        >
            {children}
        </AuthAssertHelper>
    );
}

export function WithAuthAppAssister({ children }: ChildrenProps) {
    return (
        <AuthAssertHelper
            isValidAuth={useCallback(
                (authInfo) => authInfo.appAssister != null,
                []
            )}
        >
            {children}
        </AuthAssertHelper>
    );
}

/**
 * Logged out | !Administrator -> root
 * No TOS -> acceptTermsOfService
 * Else -> SUCCESS
 */
export function WithAuthAdministrator(props: AuthAssertHelperPropsExternal) {
    return (
        <AuthAssertHelper
            isValidAuth={useCallback(
                (authInfo) => authInfo.administrator != null,
                []
            )}
            {...props}
        />
    );
}
