import { DependencyList, useEffect, useRef } from 'react';
import { Util } from '../util/util';

export function useEffectUnsafe(effect: () => void, deps: DependencyList) {
    const depCheck = useRef<any>(null);
    useEffect(
        () => {
            if (Util.sameDeps(depCheck.current, deps)) {
                return;
            }
            depCheck.current = deps;
            effect();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        deps
    );
}
