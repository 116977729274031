import { useContext, useMemo, useState } from 'react';
import { D1Modal } from '../components/design/d1modal/d1modal';
import { ChildrenProps } from '../types/generalTypes';
import { Util } from '../util/util';

type TCustomModalContext = {
    trigger: (modalProps: ModalProps) => void;
    close: () => void;
};

const CustomModalContext = Util.createContext<TCustomModalContext>();

type ModalProps = ChildrenProps & {
    zIndex?: number;
};

export function useCustomModal(): TCustomModalContext {
    return useContext(CustomModalContext);
}

export function ProvideCustomModal({ children }: ChildrenProps) {
    const [display, setDisplay] = useState<ModalProps | null>(null);
    const context: TCustomModalContext = useMemo(
        () => ({ trigger: setDisplay, close: () => setDisplay(null) }),
        []
    );
    return (
        <CustomModalContext.Provider value={context}>
            {children}
            <D1Modal
                show={display !== null}
                purpose="custom-modal"
                zIndex={display?.zIndex}
            >
                {display?.children}
            </D1Modal>
        </CustomModalContext.Provider>
    );
}
