// [HybridCodegen] hash 2f34834dbab382a7c711691259cc10ba
import * as O from '../../superql-generated/objects';
// [HybridCodegen] begin-manual-section manual-imports
import { executeQuery } from '../../util/superqlUtil';
// [HybridCodegen] end-manual-section manual-imports
export type AuthContext_Refresh = {
    o: {
        __name?: 'PublicApi';
        userAuthApi: {
            __name?: 'UserAuthApi';
            refresh: string | null;
        };
    };
    requirements: null;
};

export type QueryFrontendAuthInfo = {
    o: {
        __name?: 'Root';
        anyUserApi: {
            __name?: 'AnyUserApi';
            self: {
                __name?: 'User';
                id: number;
                name: string;
                acceptedTos: boolean;
                photoUploaded: boolean;
                programUser: {
                    __name?: 'ProgramUser';
                    id: number;
                    programManager: {
                        __name?: 'ProgramManager';
                        id: number;
                    } | null;
                    reviewer: {
                        __name?: 'Reviewer';
                        id: number;
                    } | null;
                    program: {
                        __name?: 'Program';
                        id: number;
                        currentProgTemplate: {
                            __name?: 'ProgTemplate';
                            id: number;
                        };
                    };
                } | null;
                recommender: {
                    __name?: 'Recommender';
                    id: number;
                } | null;
                applicant: {
                    __name?: 'Applicant';
                    id: number;
                    documents: Array<{
                        __name?: 'AppAssistDocument';
                        type: O.AppAssistDocumentType;
                        uploaded: boolean;
                        appAssister: {
                            __name?: 'AppAssister';
                            user: {
                                __name?: 'User';
                                email: string;
                            };
                        };
                    }>;
                    scores: {
                        __name?: 'ApplicantScores';
                        verificationComplete: boolean;
                    };
                    acceptedDocumentDisclosure: boolean;
                } | null;
                appAssister: {
                    __name?: 'AppAssister';
                    id: number;
                } | null;
                autoPopApi: {
                    __name?: 'AutoPopApi';
                    shownPopKeys: Array<string>;
                };
                administrator: {
                    __name?: 'Administrator';
                    id: number;
                } | null;
                accountAccessApi: {
                    __name?: 'AccountAccessApi';
                    numAccesses: number;
                    hasSourceUser: boolean;
                } | null;
            };
        };
    };
    requirements: null;
};
async function executeQueryFrontendAuthInfo(): Promise<
    QueryFrontendAuthInfo['o']
> {
    return await executeQuery('8c05141a904d65eb009138dfc9e5ea49', {});
}
export type FrontendAuthInfoProgram = {
    o: {
        __name?: 'Program';
        id: number;
        currentProgTemplate: {
            __name?: 'ProgTemplate';
            id: number;
        };
    };
    requirements: {
        __name?: 'Program';
        id: number;
    };
};

export type FrontendAuthInfoProgramUser = {
    o: {
        __name?: 'ProgramUser';
        id: number;
        programManager: {
            __name?: 'ProgramManager';
            id: number;
        } | null;
        reviewer: {
            __name?: 'Reviewer';
            id: number;
        } | null;
        program: {
            __name?: 'Program';
            id: number;
            currentProgTemplate: {
                __name?: 'ProgTemplate';
                id: number;
            };
        };
    };
    requirements: {
        __name?: 'ProgramUser';
        id: number;
    };
};

export type FrontendAuthInfoApplicantDocument = {
    o: {
        __name?: 'AppAssistDocument';
        type: O.AppAssistDocumentType;
        uploaded: boolean;
        appAssister: {
            __name?: 'AppAssister';
            user: {
                __name?: 'User';
                email: string;
            };
        };
    };
    requirements: {
        __name?: 'AppAssistDocument';
        id: number;
    };
};

export type FrontendAuthInfoUser = {
    o: {
        __name?: 'User';
        id: number;
        name: string;
        acceptedTos: boolean;
        photoUploaded: boolean;
        programUser: {
            __name?: 'ProgramUser';
            id: number;
            programManager: {
                __name?: 'ProgramManager';
                id: number;
            } | null;
            reviewer: {
                __name?: 'Reviewer';
                id: number;
            } | null;
            program: {
                __name?: 'Program';
                id: number;
                currentProgTemplate: {
                    __name?: 'ProgTemplate';
                    id: number;
                };
            };
        } | null;
        recommender: {
            __name?: 'Recommender';
            id: number;
        } | null;
        applicant: {
            __name?: 'Applicant';
            id: number;
            documents: Array<{
                __name?: 'AppAssistDocument';
                type: O.AppAssistDocumentType;
                uploaded: boolean;
                appAssister: {
                    __name?: 'AppAssister';
                    user: {
                        __name?: 'User';
                        email: string;
                    };
                };
            }>;
            scores: {
                __name?: 'ApplicantScores';
                verificationComplete: boolean;
            };
            acceptedDocumentDisclosure: boolean;
        } | null;
        appAssister: {
            __name?: 'AppAssister';
            id: number;
        } | null;
        autoPopApi: {
            __name?: 'AutoPopApi';
            shownPopKeys: Array<string>;
        };
        administrator: {
            __name?: 'Administrator';
            id: number;
        } | null;
        accountAccessApi: {
            __name?: 'AccountAccessApi';
            numAccesses: number;
            hasSourceUser: boolean;
        } | null;
    };
    requirements: {
        __name?: 'User';
        id: number;
    };
};

// [HybridCodegen] begin-manual-section manual-footer
export { executeQueryFrontendAuthInfo };

// [HybridCodegen] end-manual-section manual-footer
