// [HybridCodegen] hash 4d41ee0ba3b8057c2543abbc2222a09a
// [HybridCodegen] begin-manual-section manual-imports
// replace this comment with manual code

import { FileApiOperation } from '../../../superql-generated/objects';
import { executeQuery } from '../../../util/superqlUtil';

// [HybridCodegen] end-manual-section manual-imports
export type QueryInputFile_UserPhoto = {
    o: {
        __name?: 'User';
        fileApi: {
            __name?: 'FileApi';
            getSignedUrl: string;
        };
    };
    requirements: {
        __name?: 'User';
        id: number;
    };
};
async function executeQueryInputFile_UserPhoto(
    object: QueryInputFile_UserPhoto['requirements'],
    var_fileApi_getSignedUrl_operation: FileApiOperation
): Promise<QueryInputFile_UserPhoto['o']> {
    return await executeQuery('07a114eb5b99b2b3a2e5594bc07b2ef4', {
        loader_id: object.id,
        var_fileApi_getSignedUrl_operation,
    });
}
export type QueryInputFile_ApplicantScoreReport = {
    o: {
        __name?: 'Applicant';
        scoreReportFileApi: {
            __name?: 'FileApi';
            getSignedUrl: string;
        };
    };
    requirements: {
        __name?: 'Applicant';
        id: number;
    };
};
async function executeQueryInputFile_ApplicantScoreReport(
    object: QueryInputFile_ApplicantScoreReport['requirements'],
    var_scoreReportFileApi_getSignedUrl_operation: FileApiOperation,
    var_scoreReportFileApi_reportUuid: string
): Promise<QueryInputFile_ApplicantScoreReport['o']> {
    return await executeQuery('b1cf0899656f907d61f66add259f11ea', {
        loader_id: object.id,
        var_scoreReportFileApi_getSignedUrl_operation,
        var_scoreReportFileApi_reportUuid,
    });
}
// [HybridCodegen] begin-manual-section manual-footer
export {
    executeQueryInputFile_UserPhoto,
    executeQueryInputFile_ApplicantScoreReport,
};

// [HybridCodegen] end-manual-section manual-footer
