import { css } from '@emotion/react';
import { ClassNameProps, MyGlobalProps } from '../../../types/generalTypes';
import { CColor } from '../../../util/commonCSS';
import { D1ModalBase } from './d1modalBase';

const modalStyle = css({
    background: 'white',
    border: `1px solid ${CColor.hC6C6C6}`,
    borderRadius: '12px',
    padding: '58px',
    width: '822px',
    margin: '50px 0',
});

type Props = React.ComponentProps<typeof D1ModalBase> &
    ClassNameProps &
    MyGlobalProps;

export function D1Modal({ children, className, ...props }: Props) {
    return (
        <D1ModalBase {...props}>
            <div css={modalStyle} className={className}>
                {children}
            </div>
        </D1ModalBase>
    );
}
