// [HybridCodegen] hash 3a15ba90919974b78287e308824e1734
// This file is synced from backend
export enum D1ImageType {
    Brain,
    GreenArrowLeft,
    GreenArrowDown,
    GreenCheck,
    Student,
    PlasticSurgery,
    Clock,
    RightArrowBlue,
    UploadArrow,
    RightArrowGray,
    Download,
    Search,
    EyeOpen,
    EyeClose,
    CircleX,
    Question,
    DangerRed,
    DangerYellow,
    GreenCheckTime,
    GradCap,
    LetterPaper,
    ScoreIcon,
    ScoreIconGreen,
    Anesthesiology,
    Neurology,
    Star,
    VueProfile2User,
    VueCardTick,
    VueLink,
    VuePersonalCard,
    VueSecurityCard,
    VueLanguageSquare,
    VueHambergerMenu,
    PreviousApplicant,
    RedX,
    HeroUserGroup,
    UserCheck,
}
