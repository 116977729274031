// [HybridCodegen] hash f969938c86f383d14b43d5ea67504092

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';

const Component = lazy(async () => {
    const module = await import(
        '../../../pages/applicant/applicationManager/applicantApplicationManager.route'
    );
    const Component = module.RouteApplicantApplicationManager.component;
    const wrappers = module.RouteApplicantApplicationManager.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
export const ApplicantApplicationManagerRoute: RouteObject = {
    path: 'applicationManager',

    element: <Component />,
};
