import { css } from '@emotion/react';
import { D1ImageType } from '../../../generated/syncShared/d1ImageType';
import { D1Flex } from '../d1flex';
import { D1FlexGrow1 } from '../d1flexGrow1';
import D1Image from '../d1image';
import { D1Text, D1TextStyle } from '../text/d1text';

type Props = {
    header: string;
};

export function D1ModalHeader({ header }: Props) {
    return (
        <D1Flex dir="x" css={css({ marginBottom: '20px' })}>
            <D1Text style={D1TextStyle.Header2BoldUpper}>{header}</D1Text>
            <D1FlexGrow1 />
            <D1Image image={D1ImageType.Brain} width={59} />
        </D1Flex>
    );
}
