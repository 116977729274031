import { useContext, useEffect, useMemo, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { D1Modal } from '../components/design/d1modal/d1modal';
import { D1ModalHeader } from '../components/design/d1modal/d1modalHeader';
import InputTypeahead from '../components/input/inputTypeahead';
import { RouteBuilder } from '../generated/router/routeBuilder';
import { ChildrenProps } from '../types/generalTypes';
import { executeAsync, Util } from '../util/util';

type TDevToolContext = {
    devTools: true;
};

const DevToolContext = Util.createContext<TDevToolContext>();

type HandlerContext = {
    navigate: NavigateFunction;
};

type DevAction = {
    name: string;
    handler: (ctx: HandlerContext) => Promise<void>;
};

class DevDriver {
    static async loginAsApplicant(ctx: HandlerContext) {
        await this.login(ctx, 'robertcpooley+app1@gmail.com');
    }

    static async loginAsProgramDirector(ctx: HandlerContext) {
        await this.login(ctx, 'robertcpooley+pd1@gmail.com');
    }

    static async loginAsReviewer(ctx: HandlerContext) {
        await this.login(ctx, 'robertcpooley+rev1@gmail.com');
    }

    static async loginAsAdmin(ctx: HandlerContext) {
        await this.login(ctx, 'robertcpooley+admin@gmail.com');
    }

    private static async login(ctx: HandlerContext, email: string) {
        ctx.navigate(RouteBuilder.logout());
        await this.sleep(100);
        const loginOptions = await this.get('login-options');
        const inputs = loginOptions.getElementsByTagName('input');
        inputs[0].click();
        const signInBtn = await this.get('root-sign-in-wrapper');
        signInBtn.getElementsByTagName('button')[0].click();
        const emailElement = await this.get('auto-flow-sign-in-email');
        this.update(emailElement, email);
        const password = await this.get('auto-flow-sign-in-password');
        this.update(password, 'test');
        let cta: HTMLButtonElement = await this.get('auth-flow-cta');
        cta.click();
        const code = await this.get('auth-flow-2fa-code');
        this.update(code, '1234');
        cta = await this.get('auth-flow-cta');
        cta.click();
    }

    private static sleep(ms: number): Promise<void> {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    private static async get<T extends Element>(dataCy: string): Promise<T> {
        for (let i = 0; i < 50; i++) {
            const result = document.querySelectorAll(`[data-cy="${dataCy}"]`);
            if (result.length > 0) {
                return result.item(0) as any;
            }
            await this.sleep(100);
        }
        throw new Error(`Failed to find element with data-cy="${dataCy}"`);
    }

    private static update(element: Element, value: string) {
        const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
            window.HTMLInputElement.prototype,
            'value'
        )?.set;
        nativeInputValueSetter!.call(element, value);
        const ev2 = new Event('input', { bubbles: true });
        element.dispatchEvent(ev2);
    }
}

const actionMap: Record<string, DevAction> = {
    loginAsApplicant: {
        name: 'Login as applicant',
        handler: DevDriver.loginAsApplicant,
    },
    loginAsProgramDirector: {
        name: 'Login as program director',
        handler: DevDriver.loginAsProgramDirector,
    },
    loginAsReviewer: {
        name: 'Login as reviewer',
        handler: DevDriver.loginAsReviewer,
    },
    loginAsAdmin: {
        name: 'Login as admin',
        handler: DevDriver.loginAsAdmin,
    },
};

export function ProvideDevTools({ children }: ChildrenProps) {
    const existingDevToolContext = useContext(DevToolContext);
    if (existingDevToolContext) {
        return <>{children}</>;
    } else {
        return (
            <DevToolContext.Provider value={{ devTools: true }}>
                <ProvideDevToolsImpl>{children}</ProvideDevToolsImpl>
            </DevToolContext.Provider>
        );
    }
}

function ProvideDevToolsImpl({ children }: ChildrenProps) {
    const navigate = useNavigate();
    const handlerContext: HandlerContext = useMemo(
        () => ({ navigate }),
        [navigate]
    );
    const [show, setShow] = useState<boolean>(false);
    useEffect(() => {
        const listener = (e: KeyboardEvent) => {
            if (e.shiftKey && e.code === 'Backslash') {
                e.preventDefault();
                setShow((show) => !show);
            }
        };
        document.addEventListener('keydown', listener);
        console.log('dev tool mount');
        return () => {
            console.log('dev tool unmount');
            document.removeEventListener('keydown', listener);
        };
    }, []);
    const options = useMemo(() => {
        const options: Record<string, string> = {};
        for (const [k, action] of Object.entries(actionMap)) {
            options[k] = action.name;
        }
        return options;
    }, []);
    return (
        <>
            {children}
            <D1Modal purpose="devToolsModal" show={show}>
                <D1ModalHeader header="Dev tools" />
                <InputTypeahead
                    value={null}
                    onChange={(action) =>
                        executeAsync(async () => {
                            if (action === null) return;
                            setShow(false);
                            await actionMap[action].handler.bind(DevDriver)(
                                handlerContext
                            );
                        })
                    }
                    options={options}
                    autoFocus
                />
            </D1Modal>
        </>
    );
}
