import { useMemo } from 'react';
import { D1Flex } from '../design/d1flex';

export type InputRadioOptions = Record<string, React.ReactNode>;

export type InputRadioProps<T> = {
    value: Exclude<keyof T, symbol> | null;
    onChange: (value: keyof T) => void;
    radioName?: string;
    options: T;
    horizontal?: boolean;
    disabled?: boolean;
};

let GLOBAL_INPUT_RADIO_NEXT_ID = 1;

export default function InputRadio<T extends InputRadioOptions>({
    radioName: radioNameRaw,
    options,
    value,
    onChange,
    horizontal,
    disabled,
}: InputRadioProps<T>) {
    const globalRadioName = useMemo(
        () => `psca-input-radio-${GLOBAL_INPUT_RADIO_NEXT_ID++}`,
        []
    );
    const radioName = radioNameRaw ?? globalRadioName;
    const children = Object.keys(options).map((key) => {
        const checked = key === value;
        const inpComponent = (
            <input
                type="radio"
                name={radioName}
                checked={checked}
                onChange={(e) => {
                    if (e.target.checked) {
                        onChange(key);
                    }
                }}
                disabled={disabled && !checked}
            />
        );
        const labelComponent = <label>{options[key]}</label>;
        return horizontal ? (
            <D1Flex key={key} dir="y" alignItems="center">
                {labelComponent}
                {inpComponent}
            </D1Flex>
        ) : (
            <div key={key}>
                {inpComponent}
                {labelComponent}
            </div>
        );
    });
    return (
        <D1Flex dir={horizontal ? 'x' : 'y'} gap={horizontal ? 10 : 0}>
            {children}
        </D1Flex>
    );
}
