import { useContext, useState } from 'react';
import { D1EasyModal } from '../components/design/d1modal/d1easyModal';
import { ChildrenProps } from '../types/generalTypes';
import { Util } from '../util/util';

type TEasyModalContext = {
    onTrigger: (modalProps: ModalProps) => void;
};

const EasyModalContext = Util.createContext<TEasyModalContext>();

type ModalProps = Omit<
    React.ComponentProps<typeof D1EasyModal>,
    'onSecondary' | 'id' | 'show'
>;

type TEasyModal = {
    trigger: (modalProps: ModalProps) => void;
};

export function useEasyModal(): TEasyModal {
    const { onTrigger } = useContext(EasyModalContext);
    return { trigger: onTrigger };
}

export function ProvideEasyModal({ children }: ChildrenProps) {
    const [display, setDisplay] = useState<ModalProps | null>(null);
    let easyModal = null;
    if (display !== null) {
        const { onPrimary, ...props } = display;
        easyModal = (
            <D1EasyModal
                {...props}
                id="easyModal"
                show
                onPrimary={() => {
                    const maybePromise = onPrimary();
                    if (typeof maybePromise === 'object') {
                        return new Promise((resolve, reject) =>
                            maybePromise
                                .then(resolve)
                                .catch(reject)
                                .finally(() => setDisplay(null))
                        );
                    } else {
                        setDisplay(null);
                    }
                }}
                onSecondary={() => setDisplay(null)}
            />
        );
    }
    return (
        <EasyModalContext.Provider
            value={{
                onTrigger: setDisplay,
            }}
        >
            {children}
            {easyModal}
        </EasyModalContext.Provider>
    );
}
