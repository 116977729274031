import { css, SerializedStyles } from '@emotion/react';
import React from 'react';
import {
    ChildrenProps,
    ClassNameProps,
    MyGlobalProps,
} from '../../types/generalTypes';
import { CColor, CGradient } from '../../util/commonCSS';
import { D1Spinner, D1SpinnerSize } from './spinner/d1spinner';
import { D1TextBase } from './text/d1textBase';

export type D1ButtonConfig = {
    css: SerializedStyles;
    text: {
        weight: React.ComponentProps<typeof D1TextBase>['weight'];
        size: number;
        lineHeight: number;
        color: string;
    };
};

type ButtonStyleOptions = { size: 'sm' | 'md' | 'lg' };

export class D1ButtonStyle {
    static Success(options: ButtonStyleOptions): D1ButtonConfig {
        return this.standard(options, {
            background: CColor.h249690,
            text: CColor.hFFFFFF,
        });
    }

    static Secondary(options: ButtonStyleOptions): D1ButtonConfig {
        return this.standard(options, {
            background: CColor.hFFFFFF,
            text: CColor.h249690,
            border: CColor.h249690,
        });
    }

    static Gradient(options: ButtonStyleOptions): D1ButtonConfig {
        return this.standard(options, {
            background: CGradient.greenGradient,
            text: CColor.hFFFFFF,
        });
    }

    private static standard(
        options: ButtonStyleOptions,
        colors: {
            background: string;
            text: string;
            border?: string;
        }
    ): D1ButtonConfig {
        const s = <T,>(sm: T, md: T, lg: T) => {
            switch (options.size) {
                case 'sm':
                    return sm;
                case 'md':
                    return md;
                case 'lg':
                    return lg;
            }
        };
        return {
            css: css({
                background: colors.background,
                border:
                    colors.border != null
                        ? `1px solid ${colors.border}`
                        : undefined,
                borderRadius: '7px',
                padding: `${s(6, 8, 12)}px ${s(16.5, 30, 30)}px`,
            }),
            text: {
                weight: 500,
                size: s(12, 16, 20),
                lineHeight: s(18, 24, 30),
                color: colors.text,
            },
        };
    }
}

type Props = ClassNameProps &
    ChildrenProps &
    MyGlobalProps & {
        style: D1ButtonConfig;
        spinner?: boolean;
        dropdown?: boolean; // controls whether to show the 'v' at the end
        // passthrough
        disabled?: boolean;
        onClick?: () => void;
    };

const style = {
    common: css({
        border: 'none',
        outline: 'none',
        cursor: 'pointer',
    }),
    disabled: css({
        opacity: 0.5,
        cursor: 'default',
    }),
};

export const D1Button = React.forwardRef<HTMLButtonElement, Props>(
    ({ children, style: config, spinner, dropdown, ...props }: Props, ref) => {
        return (
            <button
                ref={ref}
                css={[
                    style.common,
                    props.disabled ? style.disabled : undefined,
                    config.css,
                    css({
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '5px',
                    }),
                ]}
                {...props}
            >
                <D1TextBase {...config.text}>{children}</D1TextBase>
                {dropdown && <DropdownV color={config.text.color} />}
                {spinner && <D1Spinner size={D1SpinnerSize.Small} />}
            </button>
        );
    }
);

function DropdownV({ color }: { color: string }) {
    return (
        <svg
            width="10"
            height="7"
            viewBox="0 0 10 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            css={css({ marginLeft: '5px' })}
        >
            <path
                d="M5 6.83334L0 1.83334L1.16667 0.666672L5 4.50001L8.83333 0.666672L10 1.83334L5 6.83334Z"
                fill={color}
            />
        </svg>
    );
}
