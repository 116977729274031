import { css, SerializedStyles } from '@emotion/react';
import { rem } from 'polished';
import {
    ChildrenProps,
    ClassNameProps,
    MyGlobalProps,
} from '../../../types/generalTypes';
import { CColor } from '../../../util/commonCSS';

export enum D1TextStyle {
    Header2,
    Header2BoldUpper,
    Header3,
    Header4,
    SubText,
    FooterText,
    ButtonText,
    GreenButtonText,
    AuthFlowGreen,
    AuthFlowCTA,
    AuthFlowGeneral,
    ProfilePicInitials,
    NavLeftWhite,
    NavLeftGreen,
    SpecialtyCardHeader,
    NormalCard,
    GreenLink,
    Green,
    ErrorMessage,
    GreenBackText,
}

type Props = ChildrenProps &
    ClassNameProps &
    MyGlobalProps & {
        style: D1TextStyle;
        align?: 'right' | 'center';
        onClick?: () => void;
    };

export const textCSS: Record<D1TextStyle, SerializedStyles> = {
    [D1TextStyle.Header2]: css({
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '32px',
        color: CColor.h171717,
        lineHeight: '39px',
    }),
    [D1TextStyle.Header2BoldUpper]: css({
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '32px',
        textTransform: 'uppercase',
        color: CColor.h171717,
        lineHeight: '39px',
    }),
    [D1TextStyle.Header3]: css({
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '28px',
        color: CColor.h171717,
        lineHeight: '42px',
    }),
    [D1TextStyle.Header4]: css({
        color: CColor.h323232,
        fontFamily: 'Poppins',
        fontSize: rem(18),
        fontStyle: 'normal',
        fontWeight: 600,
    }),
    [D1TextStyle.SubText]: css({
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        color: CColor.h5A5A5A,
        lineHeight: '24px',
    }),
    [D1TextStyle.FooterText]: css({
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        color: CColor.hF7F7F7,
        lineHeight: '24px',
    }),
    [D1TextStyle.ButtonText]: css({
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '30px',
        color: CColor.hFFFFFF,
    }),
    [D1TextStyle.GreenButtonText]: css({
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '30px',
        color: CColor.h41C1BA,
    }),
    [D1TextStyle.AuthFlowGreen]: css({
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: CColor.h249690,
    }),
    [D1TextStyle.AuthFlowCTA]: css({
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '36px',
        color: CColor.hFFFFFF,
    }),
    [D1TextStyle.AuthFlowGeneral]: css({
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: CColor.h535353,
    }),
    [D1TextStyle.ProfilePicInitials]: css({
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '48px',
        lineHeight: '24px',
        color: CColor.hFFFFFF,
    }),
    [D1TextStyle.NavLeftWhite]: css({
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',
        color: CColor.hFFFFFF,
    }),
    [D1TextStyle.NavLeftGreen]: css({
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        color: CColor.h249690,
    }),
    [D1TextStyle.SpecialtyCardHeader]: css({
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '27px',
        color: CColor.h474747,
    }),
    [D1TextStyle.NormalCard]: css({
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '24px',
        color: CColor.h596173,
    }),
    [D1TextStyle.GreenLink]: css({
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '27px',
        color: CColor.h249690,
        cursor: 'pointer',
    }),
    [D1TextStyle.Green]: css({
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '27px',
        color: CColor.h249690,
    }),
    [D1TextStyle.ErrorMessage]: css({
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '27px',
        color: 'red',
    }),
    [D1TextStyle.GreenBackText]: css({
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
        color: CColor.h249690,
    }),
};

export function D1Text({ children, style, align, ...props }: Props) {
    return (
        <div
            css={[
                textCSS[style],
                css({
                    textAlign: align,
                    cursor: props.onClick != null ? 'pointer' : undefined,
                }),
            ]}
            {...props}
        >
            {children}
        </div>
    );
}
