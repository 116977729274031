// [HybridCodegen] hash f7efd9ea0a10bd8367bc3dc2c8762122

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';

const Component = lazy(async () => {
    const module = await import(
        '../../../pages/faculty/recommender/facultyRecommendations.route'
    );
    const Component = module.RouteFacultyRecommendations.component;
    const wrappers = module.RouteFacultyRecommendations.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
export const FacultyRecommendationsRoute: RouteObject = {
    path: 'recommendations',

    element: <Component />,
};
