// [HybridCodegen] hash e0c6e8b3694845997a88472d3db6cc3e

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';

const Component = lazy(async () => {
    const module = await import('../../../pages/public/root/root.route');
    const Component = module.RouteRoot.component;
    const wrappers = module.RouteRoot.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
export const RootRoute: RouteObject = {
    path: '/',

    element: <Component />,
};
