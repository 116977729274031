import { InputTextArea } from '@yesness/react-input';
import { useEffect, useMemo, useRef } from 'react';
import { SharedUtil } from '../../generated/syncShared/syncShared';
import { D1Text, D1TextStyle } from '../design/text/d1text';

type Props = React.ComponentProps<typeof InputTextArea> & {
    maxWordCount?: number;
    minHeight?: number;
    maxHeight?: number;
    autoGrow?: boolean;
};

export function InputTextAreaEx({
    maxWordCount,
    minHeight,
    maxHeight,
    autoGrow,
    onChange,
    value,
    ...props
}: Props) {
    const ref = useRef<HTMLTextAreaElement | null>(null);

    useEffect(() => {
        const { current: el } = ref;
        if (el === null) {
            return;
        }
        el.style.height = 'auto';
        el.style.overflowY = 'hidden';
        const height = Math.max(
            minHeight ?? 86,
            Math.min(
                maxHeight ?? Number.MAX_SAFE_INTEGER,
                autoGrow ? el.scrollHeight : 0
            )
        );
        el.style.height = `${height}px`;
        if (height < el.scrollHeight) {
            el.style.overflowY = 'auto';
        }
    }, [value, autoGrow, minHeight, maxHeight]);

    const wordCount = useMemo(() => SharedUtil.getNumWords(value), [value]);
    return (
        <>
            <InputTextArea
                ref={ref}
                {...props}
                value={value}
                onChange={(newValue) => {
                    if (maxWordCount != null) {
                        const newWordCount = SharedUtil.getNumWords(newValue);
                        if (
                            newWordCount > maxWordCount &&
                            // Don't block decreasing word count if over word count (for the
                            // case that word count was updated in config with data already in prod)
                            newWordCount > wordCount
                        ) {
                            return;
                        }
                        const wordCountDidNotChangeAndIsStillAtMax =
                            newWordCount === wordCount &&
                            newWordCount === maxWordCount;
                        if (wordCountDidNotChangeAndIsStillAtMax) {
                            newValue = newValue.trim(); // remove any whitespace at the end
                        }
                    }
                    onChange(newValue);
                }}
            />
            {maxWordCount != null && (
                <D1Text style={D1TextStyle.SubText}>
                    {wordCount}/{maxWordCount}
                </D1Text>
            )}
        </>
    );
}
