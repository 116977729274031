import { css } from '@emotion/react';
import { useState } from 'react';
import { ClassNameProps } from '../../../types/generalTypes';
import { executeAsync } from '../../../util/util';
import { D1Button, D1ButtonStyle } from '../d1button';
import { D1Flex } from '../d1flex';

type Props = ClassNameProps & {
    primaryText: string;
    secondaryText?: string;
    onPrimary: () => Promise<void> | void;
    onSecondary: () => void;
    primaryDisabled?: boolean;
};

export function D1ModalFooter2B({
    primaryText,
    secondaryText,
    onPrimary,
    onSecondary,
    primaryDisabled,
    ...props
}: Props) {
    const [loading, setLoading] = useState<boolean>(false);
    return (
        <D1Flex dir="x" gap={48} {...props} css={css({ marginTop: '30px' })}>
            <D1Button
                style={D1ButtonStyle.Gradient({ size: 'lg' })}
                css={css({ flexGrow: 1, flexBasis: 0 })}
                onClick={() => {
                    setLoading(true);
                    const maybePromise = onPrimary();
                    if (typeof maybePromise === 'object') {
                        executeAsync(async () => {
                            await maybePromise;
                            setLoading(false);
                        });
                    }
                }}
                disabled={loading || primaryDisabled}
                spinner={loading}
                data-cy="modal-footer-primary-btn"
            >
                {primaryText}
            </D1Button>
            <D1Button
                style={D1ButtonStyle.Secondary({ size: 'lg' })}
                css={css({ flexGrow: 1, flexBasis: 0 })}
                onClick={onSecondary}
                disabled={loading}
            >
                {secondaryText ?? 'Cancel'}
            </D1Button>
        </D1Flex>
    );
}
