import React from 'react';

export type ChildrenProps = {
    children: React.ReactNode;
};

export type ClassNameProps = {
    className?: string;
};

export type MaybeChildrenProps = Partial<ChildrenProps>;

export type MyGlobalProps = {
    'data-cy'?: string;
};
export function hackDataCy(dataCy?: string): any {
    if (dataCy == null) {
        return {};
    }
    return { 'data-cy': dataCy };
}
