// [HybridCodegen] hash f0da4c73f9319945b854d0efd400b462

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';

const Component = lazy(async () => {
    const module = await import(
        '../../../pages/faculty/customizeApplication/facultyCustomizeApplication.route'
    );
    const Component = module.RouteFacultyCustomizeApplication.component;
    const wrappers = module.RouteFacultyCustomizeApplication.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
export const FacultyCustomizeApplicationRoute: RouteObject = {
    path: 'customizeApplication',

    element: <Component />,
};
