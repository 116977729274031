// [HybridCodegen] hash baa2a4c3dfbfdb66be71f50357fa2d36

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';
import withParams from '../../../util/withParams';

const Component = lazy(async () => {
    const module = await import(
        '../../../pages/appAssister/viewDocument/appAssisterViewMSPE.route'
    );
    const Component = module.RouteAppAssisterViewMSPE.component;
    const wrappers = module.RouteAppAssisterViewMSPE.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
const WithParams = withParams(Component, { documentID: 'int' });
export const AppAssisterViewMSPERoute: RouteObject = {
    path: 'mspe/:documentID',

    element: <WithParams />,
};
