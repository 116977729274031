// [HybridCodegen] hash 1eb989ff0d0b8b030008fd160ea37b34

export class RouteBuilder {
    static routePrefix: string = '';

    private static _routeReplace(
        route: string,
        map: Record<string, string | number>
    ): string {
        for (const [k, v] of Object.entries(map)) {
            route = route.replace(new RegExp(`:${k}`, 'g'), v.toString());
        }
        return route;
    }

    static acceptTermsOfService(): string {
        return this.routePrefix + '/termsOfService';
    }
    static administrator(): string {
        return this.routePrefix + '/administrator';
    }
    static administratorEmailManager(): string {
        return this.routePrefix + '/administrator/emailManager';
    }
    static administratorHome(): string {
        return this.routePrefix + '/administrator';
    }
    static administratorIcons(): string {
        return this.routePrefix + '/administrator/icons';
    }
    static appAssister(): string {
        return this.routePrefix + '/appAssister';
    }
    static appAssisterHome(): string {
        return this.routePrefix + '/appAssister';
    }
    static appAssisterViewMSPE(documentID: number): string {
        return (
            this.routePrefix +
            this._routeReplace('/appAssister/mspe/:documentID', { documentID })
        );
    }
    static appAssisterViewTranscript(documentID: number): string {
        return (
            this.routePrefix +
            this._routeReplace('/appAssister/transcript/:documentID', {
                documentID,
            })
        );
    }
    static applicant(): string {
        return this.routePrefix + '/applicant';
    }
    static applicantMessages(): string {
        return this.routePrefix + '/applicant/messages';
    }
    static applicantAppInstance(appInstance: number): string {
        return (
            this.routePrefix +
            this._routeReplace('/applicant/applicationManager/:appInstance', {
                appInstance,
            })
        );
    }
    static applicantAppInstanceTask(appInstance: number, task: string): string {
        return (
            this.routePrefix +
            this._routeReplace(
                '/applicant/applicationManager/:appInstance/:task',
                { appInstance, task }
            )
        );
    }
    static applicantAppInstanceTaskIndex(appInstance: number): string {
        return (
            this.routePrefix +
            this._routeReplace('/applicant/applicationManager/:appInstance', {
                appInstance,
            })
        );
    }
    static applicantApplicationManager(): string {
        return this.routePrefix + '/applicant/applicationManager';
    }
    static applicantArchive(): string {
        return this.routePrefix + '/applicant/archive';
    }
    static applicantDocuments(): string {
        return this.routePrefix + '/applicant/documents';
    }
    static applicantHome(): string {
        return this.routePrefix + '/applicant';
    }
    static applicantPreviewAppTemplate(appTemplate: number): string {
        return (
            this.routePrefix +
            this._routeReplace('/applicant/home/appTemplate/:appTemplate', {
                appTemplate,
            })
        );
    }
    static applicantInterviewManager(): string {
        return this.routePrefix + '/applicant/interviewManager';
    }
    static accountAccess(): string {
        return this.routePrefix + '/faculty/accountAccess';
    }
    static facultyApplications(): string {
        return this.routePrefix + '/faculty/applications';
    }
    static facultyApplicationsIndex(): string {
        return this.routePrefix + '/faculty/applications';
    }
    static facultyViewApplication(progInstanceID: number): string {
        return (
            this.routePrefix +
            this._routeReplace('/faculty/applications/:progInstanceID', {
                progInstanceID,
            })
        );
    }
    static facultyArchive(): string {
        return this.routePrefix + '/faculty/archive';
    }
    static facultyCompletedReviews(): string {
        return this.routePrefix + '/faculty/completedReviews';
    }
    static facultyCustomizeApplication(): string {
        return this.routePrefix + '/faculty/customizeApplication';
    }
    static facultyEditRubric(): string {
        return this.routePrefix + '/faculty/editRubric';
    }
    static faculty(): string {
        return this.routePrefix + '/faculty';
    }
    static facultyGroups(): string {
        return this.routePrefix + '/faculty/groups';
    }
    static facultyViewGroup(group: number): string {
        return (
            this.routePrefix +
            this._routeReplace('/faculty/viewGroup/:group', { group })
        );
    }
    static facultyHome(): string {
        return this.routePrefix + '/faculty';
    }
    static facultyInterviewManager(): string {
        return this.routePrefix + '/faculty/interviewManager';
    }
    static facultyManageReviews(): string {
        return this.routePrefix + '/faculty/manageReviews';
    }
    static facultyMyTeam(): string {
        return this.routePrefix + '/faculty/myTeam';
    }
    static facultyRecommendations(): string {
        return this.routePrefix + '/faculty/recommendations';
    }
    static facultyViewRecommendation(recommendationID: number): string {
        return (
            this.routePrefix +
            this._routeReplace('/faculty/recommendations/:recommendationID', {
                recommendationID,
            })
        );
    }
    static logout(): string {
        return this.routePrefix + '/logout';
    }
    static contactUs(): string {
        return this.routePrefix + '/contactUs';
    }
    static faq(): string {
        return this.routePrefix + '/faq';
    }
    static loginLink(linkKey: string): string {
        return (
            this.routePrefix +
            this._routeReplace('/loginLink/:linkKey', { linkKey })
        );
    }
    static pdfRender(token: string): string {
        return (
            this.routePrefix +
            this._routeReplace('/pdfRender/:token', { token })
        );
    }
    static pendingAccountLink(linkKey: string): string {
        return (
            this.routePrefix +
            this._routeReplace('/confirmAccount/:linkKey', { linkKey })
        );
    }
    static root(): string {
        return this.routePrefix + '/';
    }
    static verifyEmail(verifyKey: string): string {
        return (
            this.routePrefix +
            this._routeReplace('/verifyEmail/:verifyKey', { verifyKey })
        );
    }
    static userNoProfiles(): string {
        return this.routePrefix + '/noProfiles';
    }
    static userSettings(): string {
        return this.routePrefix + '/settings';
    }
}
