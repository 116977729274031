import { Suspense, useMemo } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { D1SpinnerBrain } from '../components/design/spinner/d1spinnerBrain';
import * as Routes from '../generated/router/routes';
import { ProvideDevTools } from './devTools';

export default function Router() {
    const router = useMemo(() => {
        const routeObjects = Object.values(Routes);
        if ((window as any).LCA_DEV_MODE) {
            console.log('injecting dev tools');
            for (const routeObject of routeObjects) {
                routeObject.element = (
                    <ProvideDevTools>{routeObject.element}</ProvideDevTools>
                );
            }
        }
        return createBrowserRouter(routeObjects);
    }, []);
    return (
        <Suspense fallback={<D1SpinnerBrain />}>
            <RouterProvider router={router} />
        </Suspense>
    );
}
