import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export type ParamProps<RouteParams> = {
    params: RouteParams;
};

type ParamType<RouteParams> = {
    [P in keyof RouteParams]: RouteParams[P] extends string ? 'string' : 'int';
};

export default function withParams<
    Props,
    RouteParams extends Record<string, string | number>
>(
    Component: React.ComponentType<Props & ParamProps<RouteParams>>,
    keys: ParamType<RouteParams>
): React.FunctionComponent<Omit<Props, 'params'>> {
    function ParamComponent(props: Props) {
        const navigate = useNavigate();
        const routeParams = useParams();
        const params: any = useMemo(() => {
            const params: any = {};
            for (const k of Object.keys(routeParams)) {
                const val = routeParams[k];
                if (val == null) {
                    return null;
                }
                const t = keys[k];
                if (t === 'int') {
                    const num = parseInt(val);
                    if (isNaN(num)) {
                        return null;
                    }
                    params[k] = num;
                } else {
                    params[k] = val;
                }
            }
            return params;
        }, [routeParams]);
        useEffect(() => {
            if (params === null) {
                navigate('/');
            }
        }, [params, navigate]);
        if (params === null) {
            return null;
        }
        return <Component {...props} params={params} />;
    }
    return ParamComponent as any;
}
