import { css } from '@emotion/react';
import { ChildrenProps, ClassNameProps } from '../types/generalTypes';
import { CColor } from '../util/commonCSS';
import { D1TextBase } from './design/text/d1textBase';

export function FieldLabel(props: ChildrenProps & ClassNameProps) {
    return (
        <D1TextBase
            weight={400}
            size={16}
            lineHeight={24}
            color={CColor.h848484}
            css={css({ marginBottom: '19px' })}
            {...props}
        />
    );
}

export function Label({
    label,
    children,
    ...props
}: { label: string } & ChildrenProps & ClassNameProps) {
    return (
        <div {...props}>
            <FieldLabel>{label}</FieldLabel>
            {children}
        </div>
    );
}
