import { ChildrenProps } from '../../../types/generalTypes';
import { D1Modal } from './d1modal';
import { D1ModalFooter2B } from './d1modalFooter2b';
import { D1ModalHeader } from './d1modalHeader';

type Props = ChildrenProps & {
    id: string;
    show: boolean;
    header: string;
} & Omit<React.ComponentProps<typeof D1ModalFooter2B>, 'className'>;

export function D1EasyModal({ id, show, children, header, ...props }: Props) {
    return (
        <D1Modal
            purpose={id}
            show={show}
            data-cy={show ? 'modal-show' : undefined}
        >
            <D1ModalHeader header={header} />
            {children}
            <D1ModalFooter2B {...props} />
        </D1Modal>
    );
}
