import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { ChildrenProps, MyGlobalProps } from '../../../types/generalTypes';

const modalStyle = {
    outer: css({
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
    }),
    inner1: css({
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        overflow: 'auto',
    }),
    cover: css({
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        background: 'black',
        opacity: 0.5,
    }),
    modal: css({
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        minHeight: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }),
};

type Props = MyGlobalProps &
    ChildrenProps & {
        purpose: string;
        show: boolean;
        zIndex?: number;
    };

let GLOBAL_MODAL_NEXT_ID = 1;

export function D1ModalBase({
    purpose,
    show,
    children,
    zIndex,
    ...props
}: Props) {
    const [modalDomID, setModalDomID] = useState<string | null>(null);
    useEffect(() => {
        const id = `customModalID_${GLOBAL_MODAL_NEXT_ID++}`;
        const div = document.createElement('div');
        div.id = id;
        div.setAttribute('psca-purpose', purpose);
        document.body.appendChild(div);
        setModalDomID(id);
        return () => div.remove();
    }, [purpose]);
    if (modalDomID === null || !show) {
        return null;
    }
    return ReactDOM.createPortal(
        <div
            css={[modalStyle.outer, css({ zIndex: zIndex ?? 500 })]}
            {...props}
        >
            <div css={modalStyle.cover} />
            <div css={modalStyle.inner1}>
                <div css={[modalStyle.modal]}>{children}</div>
            </div>
        </div>,
        document.getElementById(modalDomID)!
    );
}
