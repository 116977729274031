import { useMemo } from 'react';
import FilterArrowOptions, {
    FilterArrowOptionsProps,
} from '../superSearchBar/filterArrowOptions';
import { ArrowOption } from './arrowOptions';

export type FilterArrowOption =
    | {
          display: string;
          alwaysVisible?: boolean;
      }
    | string;

export type FilterArrowOptionMap = Record<string, FilterArrowOption>;

type Props<T extends FilterArrowOptionMap> = Omit<
    FilterArrowOptionsProps,
    'options' | 'onSelect' | 'excludeAndAlwaysShow'
> & {
    options: T;
    onSelect: (optionID: keyof T) => void;
};

export function FilterArrowOptionsMap<T extends FilterArrowOptionMap>({
    options,
    ...props
}: Props<T>) {
    const { optionsList, excludeAndAlwaysShow } = useMemo(() => {
        const optionsList: ArrowOption[] = [];
        const excludeAndAlwaysShow: string[] = [];
        for (const id of Object.keys(options)) {
            const opt = options[id];
            let display;
            if (typeof opt === 'string') {
                display = opt;
            } else {
                display = opt.display;
                if (opt.alwaysVisible) {
                    excludeAndAlwaysShow.push(id);
                }
            }
            optionsList.push({
                id,
                display,
            });
        }
        return { optionsList, excludeAndAlwaysShow };
    }, [options]);
    return (
        <FilterArrowOptions
            options={optionsList}
            excludeAndAlwaysShow={excludeAndAlwaysShow}
            {...props}
        />
    );
}
