// [HybridCodegen] hash 0a0e755be69929ae317f46aee22ce65f

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';
import * as FacultyApplicationsIndexRouteChildren from './facultyApplicationsIndex';
import * as FacultyViewApplicationRouteChildren from './facultyViewApplication';

const Component = lazy(async () => {
    const module = await import(
        '../../../pages/faculty/applications/facultyApplications.route'
    );
    const Component = module.RouteFacultyApplications.component;
    const wrappers = module.RouteFacultyApplications.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
export const FacultyApplicationsRoute: RouteObject = {
    path: 'applications',

    element: <Component />,
    children: [
        ...Object.values(FacultyApplicationsIndexRouteChildren),
        ...Object.values(FacultyViewApplicationRouteChildren),
    ],
};
