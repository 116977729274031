// [HybridCodegen] hash 41b6e7d8216d477eae00321ffda4db17
// [HybridCodegen] begin-manual-section manual-imports
import { executeQuery } from '../../util/superqlUtil';
// [HybridCodegen] end-manual-section manual-imports
export type MutAutoPopContext_SetShown = {
    o: {
        __name?: 'User';
        autoPopApi: {
            __name?: 'AutoPopApi';
            setPopKeyShown: boolean;
        };
    };
    requirements: {
        __name?: 'User';
        id: number;
    };
};
async function executeMutAutoPopContext_SetShown(
    object: MutAutoPopContext_SetShown['requirements'],
    var_autoPopApi_setPopKeyShown_popKey: string,
    var_autoPopApi_setPopKeyShown_show: boolean
): Promise<MutAutoPopContext_SetShown['o']> {
    return await executeQuery('bad8f14801297752bd3fca04175d3c29', {
        loader_id: object.id,
        var_autoPopApi_setPopKeyShown_popKey,
        var_autoPopApi_setPopKeyShown_show,
    });
}
// [HybridCodegen] begin-manual-section manual-footer
export { executeMutAutoPopContext_SetShown };

// [HybridCodegen] end-manual-section manual-footer
