// [HybridCodegen] hash dfa5c1ae5604e71f980bf524a5061914

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';

const Component = lazy(async () => {
    const module = await import(
        '../../../pages/faculty/completedReviews/facultyCompletedReviews.route'
    );
    const Component = module.RouteFacultyCompletedReviews.component;
    const wrappers = module.RouteFacultyCompletedReviews.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
export const FacultyCompletedReviewsRoute: RouteObject = {
    path: 'completedReviews',

    element: <Component />,
};
