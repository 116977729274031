// [HybridCodegen] hash a3aa7caa2846246748df06295316075c

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';
import withParams from '../../../util/withParams';
import * as ApplicantAppInstanceTaskRouteChildren from './applicantAppInstanceTask';
import * as ApplicantAppInstanceTaskIndexRouteChildren from './applicantAppInstanceTaskIndex';

const Component = lazy(async () => {
    const module = await import(
        '../../../pages/applicant/applicationManager/appInstance/applicantAppInstance/applicantAppInstance.route'
    );
    const Component = module.RouteApplicantAppInstance.component;
    const wrappers = module.RouteApplicantAppInstance.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
const WithParams = withParams(Component, { appInstance: 'int' });
export const ApplicantAppInstanceRoute: RouteObject = {
    path: 'applicationManager/:appInstance',

    element: <WithParams />,
    children: [
        ...Object.values(ApplicantAppInstanceTaskRouteChildren),
        ...Object.values(ApplicantAppInstanceTaskIndexRouteChildren),
    ],
};
