import { css } from '@emotion/react';
import { D1ImageType } from '../../../generated/syncShared/d1ImageType';
import { CColor } from '../../../util/commonCSS';
import { D1Flex } from '../d1flex';
import D1Image from '../d1image';
import './d1spinnerBrain.scss';

export function D1SpinnerBrain() {
    return (
        <D1Flex
            dir="x"
            justifyContent="center"
            alignItems="center"
            css={css({
                height: '100%',
            })}
        >
            <div css={css({ position: 'relative' })}>
                <D1Image
                    image={D1ImageType.Brain}
                    css={css({
                        animation: 'rotating 6s linear infinite',
                    })}
                />
                <div
                    css={css({
                        position: 'absolute',
                        left: '-10px',
                        top: '-12px',
                        width: 'calc(100% + 20px)',
                        height: 'calc(100% + 20px)',
                        border: `3px solid ${CColor.h249690}`,
                        borderRightColor: 'transparent',
                        borderRadius: '50%',
                        animation: 'spinner-border 4s linear infinite',
                        animationDirection: 'reverse',
                        boxSizing: 'border-box',
                    })}
                />
            </div>
        </D1Flex>
    );
}
