import { css } from '@emotion/react';
import { InputText } from '@yesness/react-input';
import { useEffect, useRef, useState } from 'react';
import { ActionContext } from '../../contexts/actionContext';
import { State } from '../../hooks/stateHooks';
import {
    ClassNameProps,
    hackDataCy,
    MyGlobalProps,
} from '../../types/generalTypes';
import {
    FilterArrowOptionMap,
    FilterArrowOptionsMap,
} from '../arrowOptions/filterArrowOptionsMap';

type Props<T extends FilterArrowOptionMap> = MyGlobalProps &
    ClassNameProps & {
        options: T;
        value: keyof T | null;
        onChange: (value: keyof T | null) => void;
        placeholder?: string;
        actionContext?: ActionContext;
        autoFocus?: boolean;
        filter?: State<string>;
    };

export default function InputTypeahead<T extends FilterArrowOptionMap>({
    options,
    value,
    onChange,
    placeholder,
    actionContext,
    autoFocus,
    filter: outerFilter,
    'data-cy': dataCy,
    ...props
}: Props<T>) {
    const filterRef = useRef<HTMLInputElement | null>(null);
    const [innerFilter, setInnerFilter] = useState<string>('');
    const [filterFocus, setFilterFocus] = useState<boolean>(false);

    const filter = outerFilter?.value ?? innerFilter;
    const setFilter = outerFilter?.onChange ?? setInnerFilter;

    useEffect(() => {
        const { current } = filterRef;
        if (current === null) {
            return;
        }
        if (autoFocus) {
            current.focus();
        }
    }, [autoFocus]);

    const getDisplay = (value: keyof T) => {
        const opt = options[value];
        if (!opt) {
            return filter;
        }
        if (typeof opt === 'string') {
            return opt;
        } else {
            return opt.display;
        }
    };

    return (
        <div css={css({ position: 'relative' })} {...props}>
            <InputText
                ref={filterRef}
                value={value !== null ? getDisplay(value) : filter}
                onChange={setFilter}
                placeholder={placeholder}
                onFocus={() => {
                    onChange(null);
                    setFilterFocus(true);
                }}
                onBlur={() => setFilterFocus(false)}
                disabled={actionContext?.loading}
                {...hackDataCy(dataCy)}
            />
            {filterFocus && (
                <div
                    css={css({
                        position: 'absolute',
                        top: '100%',
                        zIndex: 1,
                    })}
                    onMouseDown={(e) => {
                        e.preventDefault();
                    }}
                    data-cy={`${dataCy}-results`}
                >
                    <FilterArrowOptionsMap
                        options={options}
                        filter={filter}
                        onSelect={(optionKey) => {
                            onChange(optionKey);
                            setFilter('');
                            filterRef.current?.blur();
                        }}
                        maxHeight={200}
                    />
                </div>
            )}
        </div>
    );
}
