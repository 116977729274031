// [HybridCodegen] hash d2073811b6397aa1699a9d9da5cf5a87

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';

const Component = lazy(async () => {
    const module = await import(
        '../../../pages/administrator/icons/administratorIcons.route'
    );
    const Component = module.RouteAdministratorIcons.component;
    const wrappers = module.RouteAdministratorIcons.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
export const AdministratorIconsRoute: RouteObject = {
    path: 'icons',

    element: <Component />,
};
