// [HybridCodegen] hash 8ae76929e93b5a5e18ef28ee2b81ad91

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';

const Component = lazy(async () => {
    const module = await import(
        '../../../pages/faculty/editRubric/facultyEditRubric.route'
    );
    const Component = module.RouteFacultyEditRubric.component;
    const wrappers = module.RouteFacultyEditRubric.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
export const FacultyEditRubricRoute: RouteObject = {
    path: 'editRubric',

    element: <Component />,
};
