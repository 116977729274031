import { IconProps } from './types';

export function IconUserCheck({ color, ...props }: IconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="61"
            height="60"
            viewBox="0 0 61 60"
            fill="none"
            {...props}
        >
            <path
                d="M45.2653 31.0202C41.6006 31.0074 38.0949 32.5183 35.5859 35.1927C31.9771 32.3104 27.4937 30.7483 22.8773 30.7658H22.8409C17.2926 30.7775 12.1439 32.9366 8.32795 36.866C4.45101 40.8321 2.33108 46.2531 2.34282 52.1352C2.34509 52.4572 2.47125 52.7652 2.69438 52.9961C2.92205 53.2244 3.23194 53.3515 3.55432 53.3481L35.3555 53.2874C35.4283 53.3723 35.5006 53.4452 35.5737 53.5301C39.7488 57.9536 46.3855 58.9882 51.707 56.0457C57.0284 53.1027 59.687 46.9285 58.1698 41.035C56.6526 35.1415 51.3448 31.0221 45.2653 31.0202ZM4.80218 50.9103C5.05676 46.1439 6.89825 41.7901 10.06 38.5521C13.416 35.0956 17.9589 33.1914 22.8409 33.1914H22.8773C26.952 33.1755 30.91 34.5529 34.0956 37.0965C31.4116 41.2467 31.2346 46.5413 33.6353 50.8621L4.80218 50.9103ZM45.2653 55.2765C39.2435 55.2765 34.3623 50.3896 34.3623 44.361C34.3623 38.3333 39.2435 33.4459 45.2653 33.4459C51.2872 33.4459 56.1684 38.3333 56.1684 44.361C56.1684 50.3896 51.2872 55.2765 45.2653 55.2765Z"
                fill={color}
            />
            <path
                d="M22.8531 27.8793C29.925 27.8793 35.6582 22.1397 35.6582 15.0599C35.6582 7.97977 29.925 2.24023 22.8531 2.24023C15.7811 2.24023 10.0479 7.97977 10.0479 15.0599C10.0547 22.1367 15.7837 27.8724 22.8531 27.8793ZM22.8531 4.6659C28.5874 4.6659 33.2353 9.31965 33.2353 15.0599C33.2353 20.7999 28.5874 25.4536 22.8531 25.4536C17.1191 25.4536 12.4708 20.7999 12.4708 15.0599C12.4777 9.3223 17.1217 4.67311 22.8531 4.6659Z"
                fill={color}
            />
            <path
                d="M49.3477 40.6611L43.4959 45.4275L41.3517 42.9168C40.9168 42.4075 40.1524 42.348 39.644 42.7834C39.1352 43.2187 39.0757 43.9844 39.5106 44.4938L42.4181 47.9013C42.6488 48.1698 42.9849 48.3249 43.3391 48.3257C43.6164 48.3287 43.8861 48.2339 44.1017 48.0591L50.8744 42.5406C51.3858 42.1174 51.4604 41.3597 51.0418 40.8439C50.6224 40.3277 49.8667 40.2466 49.3477 40.6611Z"
                fill={color}
            />
        </svg>
    );
}
