// [HybridCodegen] hash d58160ea33977b509e6a1bc0cf7ef546
// This file is synced from backend
import {
    LetterOfRecommendationStatus,
    UserRole,
} from '../../superql-generated/objects';
import { D1ImageType } from './d1ImageType';
import { RaceEthnicityKeys } from './raceEthnicityKeys';

export const ACCESS_TOKEN_EXPIRY_SECS = 15 * 60;

export const API_STATUS_CODES = {
    maintenance: 550,
};

export const MAX_PHOTO_SIZE_BYTES = 5 * 1024 * 1024;

export const TMP_COOKIES_RESPONSE_KEY = '__cookies';

/**
 * Value
 */
export type AGValueCustomData = {
    type: 'customData';
    key: string;
};

export type AGValueConst = {
    type: 'const';
    const: any;
};

export type AGValueOperator = {
    type: 'operator';
    left: AGValue;
    operator: '===' | '!==' | 'includes' | 'index' | '||' | '&&';
    right: AGValue;
};

export type AGValueOperatorSingle = {
    type: 'operatorSingle';
    value: AGValue;
    operator: '!';
};

export type AGValueAppInstanceState = {
    type: 'appInstanceState';
};

// Calls Object.entries on provided value, returns Array<{key, value}>
export type AGValueObjectEntries = {
    type: 'objectEntries';
    object: AGValue;
};

export type AGValueContextKey = {
    type: 'contextKey';
    contextKey: string;
};

export type AGValueMaybeMap = {
    type: 'maybeMap';
    value: AGValue;
    map: Record<string, any>;
};

export type AGValueRenderDate = {
    type: 'renderDate';
    date: AGValue;
};

export type AGValueAppReviewContext = {
    type: 'appReviewContext';
    data: 'signalStatement';
};

// Will accept a const and deeply resolve any internal AGValues.
// Note that internal AGValues must be wrapped in a AGDeepResolveValueWrapper
export type AGValueDeepResolveValue = {
    type: 'deepResolveValue';
    const: any;
};

type AGDeepResolveValueWrapper = { __ag_internal_value: true; value: AGValue };

export class AGValueDeepResolveUtil {
    static wrap(value: AGValue): AGDeepResolveValueWrapper {
        return { __ag_internal_value: true, value };
    }

    static deepResolve(value: any, resolver: (value: AGValue) => any): any {
        if (value == null) {
            return value;
        } else if (Array.isArray(value)) {
            return value.map((entry) => this.deepResolve(entry, resolver));
        } else if (typeof value === 'object') {
            if (value.__ag_internal_value) {
                return resolver(value.value);
            }
            for (const key of Object.keys(value)) {
                value[key] = this.deepResolve(value[key], resolver);
            }
            return value;
        } else {
            return value;
        }
    }
}

export type AGValue =
    | AGValueOperator
    | AGValueOperatorSingle
    | AGValueCustomData
    | AGValueConst
    | AGValueAppInstanceState
    | AGValueObjectEntries
    | AGValueContextKey
    | AGValueMaybeMap
    | AGValueRenderDate
    | AGValueAppReviewContext
    | AGValueDeepResolveValue;

/**
 * Component tags
 */
export type AGComponentTags = {
    excludeFromReviewAndSubmit?: boolean;
};

/**
 * Components
 */
export type AGComponentCommon<T> = T extends any
    ? {
          type: T;
      }
    : never;

type AGComponentInputBase = {
    dataKey: string;
    label?: string;
};

export type AGComponentInputText = AGComponentInputBase & {
    type: 'text';
    allowEditAfterSubmit?: boolean;
};

export type AGComponentInputCommon = AGComponentInputBase & {
    type: 'email' | 'phone' | 'photo';
};

export type AGComponentInputInt = AGComponentInputBase & {
    type: 'int';
    range?: {
        min?: number;
        max?: number;
    };
};

export type AGComponentInputTextarea = AGComponentInputBase & {
    type: 'textarea';
    maxWordCount?: number;
    minHeight?: number;
    maxHeight?: number;
    autoGrow?: boolean;
};

type RadioSelectBase = AGComponentInputBase & {
    options: Array<{
        key: string;
        label: string;
        score?: number;
    }>;
};

export type AGComponentInputRadio = RadioSelectBase & {
    type: 'radio';
};

export type AGComponentInputSelect = RadioSelectBase & {
    type: 'select';
};

export type AGComponentInputMultiSelect = AGComponentInputBase & {
    type: 'multiSelect';
    options: Array<{
        key: string;
        label: string;
        deselectOthersOnSelect?: boolean;
    }>;
};

export type AGComponentInputDate = AGComponentInputBase & {
    type: 'date';
    format: 'mmyyyy';
};

export type AGComponentInputMulti_StyleVertical = {
    type: 'vertical';
    // Use {num} template for the current index (starting at 1)
    headerText: string;
};

export type AGComponentInputMulti_StyleVerticalColumns = {
    type: 'verticalColumns';
    // Use {num} template for the current index (starting at 1)
    headerText: string;
};

export type AGComponentInputMulti_Field = {
    key: string;
    label: string;
    input: AGComponent;
};

export type AGComponentInputMulti = AGComponentInputBase & {
    type: 'multi';
    fields: AGComponentInputMulti_Field[];
    addButtonText: string;
    style:
        | { type: 'table' }
        | AGComponentInputMulti_StyleVertical
        | AGComponentInputMulti_StyleVerticalColumns;
    min?: number;
    max?: number;
};

export type AGComponentInputMarkAsComplete = AGComponentInputBase & {
    type: 'inputMarkAsComplete';
};

export type AGComponentInputScale = AGComponentInputBase & {
    type: 'inputScale';
    maxNumber: number;
    percentile?: boolean;
    lowText: string;
    highText: string;
};

export type AGComponentInputFile = AGComponentInputBase & {
    type: 'inputFile';
    buttonText: string;
    mode: 'letter' | 'appInstance';
};

export type AGComponentTable = {
    type: 'table';
    columnHeaders: string[];
    rows: Array<AGComponent[]>;
};

export type AGComponentCondition = {
    type: 'condition';
    condition: AGValue;
    component: AGComponent;
};

export type AGComponentInputSwitch = {
    type: 'switch';
    value: AGValue; // What is switched upon
    cases: Array<{
        value: AGValue; // The value to compare
        component: AGComponent;
    }>;
    // If no fallthrough is provided and no conditions are satisfied,
    // no input will be rendered
    fallthrough?: AGComponent;
};

export type AGComponentFixed = {
    type: 'lettersOfRecommendation';
};

export type AGComponentFlex = {
    type: 'flex';
    dir: 'x' | 'y';
    gap?: number;
    children: AGComponent[];
};

export type AGComponentDiv = {
    type: 'div';
    css?: Record<string, any>;
    children: AGComponent[];
};

export type AGComponentColumns = {
    type: 'columns';
    gap?: number;
    children: AGComponent[];
};

export type AGComponentTasks_StatusResolverCustomDataBool = {
    type: 'customDataBool';
    dataKey: string;
    inProgressStatus?: AGComponentTasks_StatusResolver;
};

export type AGComponentTasks_StatusResolver =
    | {
          type:
              | 'componentCustomData'
              | 'lor'
              | 'programSelection'
              | 'payment'
              | 'reviewAndSubmit'
              | 'documents';
      }
    | AGComponentTasks_StatusResolverCustomDataBool;

export type AGComponentTasks_Task = {
    name: string;
    component: AGComponent;
    statusResolver: AGComponentTasks_StatusResolver;
    optional?: boolean;
};

export type AGComponentTasks = {
    type: 'tasks';
    tasks: Record<string, AGComponentTasks_Task>;
};

export type AGComponentCustomizeApplication_Fields = Record<
    string,
    AGComponent
>;

export type AGComponentCustomizeApplication_Task = {
    display: string;
    // Maps from fieldKey to component to render inside box
    fields: AGComponentCustomizeApplication_Fields;
};

export type AGComponentCustomizeApplication = {
    type: 'customizeApplication';
    // Maps from taskID to task config
    tasks: Record<string, AGComponentCustomizeApplication_Task>;
};

export type AGComponentRef = {
    type: 'ref';
    ref: string;
};

export type AGComponentString = {
    type: 'string';
    value: string;
};

export type AGComponentD1Text = {
    type: 'd1text';
    text?: string;
    children?: AGComponent[];
    weight: 300 | 400 | 500 | 600;
    size: number;
    color: string;
    lineHeight?: number;
    italic?: boolean;
    underline?: boolean;
};

export type AGComponentDataWrapper = {
    type: 'dataWrapper';
    dataKey: string; // Any children data changes will be stored in this single dataKey
    children: AGComponent[];
};

export type AGComponentSection = {
    type: 'section';
    display: string;
    children: AGComponent[];
    collapse?: boolean;
};

export type AGComponentProgramSelectionData = {
    type: 'programSelectionData';
    children: AGComponent[];
};

export type AGComponentDisableContext = {
    type: 'disableContext';
    // If value resolves to true, will set to disabled
    disable: AGValue;
    children: AGComponent[];
};

export type AGComponentAutoPop = AutoPopConfig & {
    type: 'autoPop';
};

export type AGComponentProgramSpecificContext = {
    type: 'programSpecificContext';
    programID: number;
    children: AGComponent[];
};

export type AGComponentAppReviewHeader = {
    type: 'appReviewHeader';
};

export type AGComponentAppReviewSection = {
    type: 'appReviewSection';
    title: string;
    children: AGComponent[];
    style?: 'main' | 'sub';
};

export type AGComponentAppReviewFieldLine = {
    type: 'appReviewFieldLine';
    fieldKey: string;
    icon?: D1ImageType;
    // Specifically for prevent responsibilities -> ability to carry out responsibilities
    flipBooleanValue?: boolean;
    labelOverride?: string;
    overrideStatuses?: {
        positive?: string[]; // green. default: ['Yes']
        negative?: string[]; // red.   default: ['No']
    };
};

export type AGComponentAppReviewCustomFieldLine = {
    type: 'appReviewCustomFieldLine';
    label: AGValue;
    value: AGValue;
};

export type AGComponentAppReviewMaybeElaborate = {
    type: 'appReviewMaybeElaborate';
    fieldKey: string;
};

export type AGComponentAppReviewScores = {
    type: 'appReviewScores';
};

export type AGComponentAppReviewTimeline = {
    type: 'appReviewTimeline';
    entries: AGValue;
    entryContextKey: string;
    startDate?: AGValue;
    stopDate?: AGValue;
    primaryText?: AGValue;
    children: AGComponent[];
};

export type AGComponentAppReviewTable_Column = {
    header: string;
    render: AGValue;
};

export type AGComponentAppReviewTable = {
    type: 'appReviewTable';
    rows: AGValue;
    contextKey: string;
    columns: AGComponentAppReviewTable_Column[];
};

export type AGComponentIterate = {
    type: 'iterate';
    contextKey: string;
    array: AGValue;
    children: AGComponent[];
};

export type AGComponentValue = {
    type: 'value';
    value: AGValue;
};

export type AGComponentApplicationSignalSelection = {
    type: 'signalSelection';
    mode: 'regular' | 'goldSilver';
};

export type AGComponentInputUnion =
    | AGComponentInputText
    | AGComponentInputCommon
    | AGComponentInputTextarea
    | AGComponentInputRadio
    | AGComponentInputSelect
    | AGComponentInputMultiSelect
    | AGComponentInputDate
    | AGComponentInputMulti
    | AGComponentInputMarkAsComplete
    | AGComponentInputScale
    | AGComponentInputFile
    | AGComponentInputInt;

export const AGComponentInputTypes: Record<
    AGComponentInputUnion['type'],
    true
> = {
    date: true,
    email: true,
    inputFile: true,
    inputMarkAsComplete: true,
    inputScale: true,
    int: true,
    multi: true,
    multiSelect: true,
    phone: true,
    photo: true,
    radio: true,
    select: true,
    text: true,
    textarea: true,
};

type AGComponentUnion =
    | AGComponentCommon<
          | 'programSelection'
          | 'signalStatements'
          | 'payment'
          | 'reviewAndSubmit'
          | 'documents'
      >
    | AGComponentInputUnion
    | AGComponentTable
    | AGComponentCondition
    | AGComponentInputSwitch
    | AGComponentFixed
    | AGComponentFlex
    | AGComponentDiv
    | AGComponentColumns
    | AGComponentTasks
    | AGComponentRef
    | AGComponentString
    | AGComponentD1Text
    | AGComponentDataWrapper
    | AGComponentCustomizeApplication
    | AGComponentSection
    | AGComponentProgramSelectionData
    | AGComponentDisableContext
    | AGComponentAutoPop
    | AGComponentProgramSpecificContext
    | AGComponentAppReviewHeader
    | AGComponentAppReviewSection
    | AGComponentAppReviewFieldLine
    | AGComponentAppReviewCustomFieldLine
    | AGComponentAppReviewScores
    | AGComponentAppReviewTimeline
    | AGComponentAppReviewTable
    | AGComponentAppReviewMaybeElaborate
    | AGComponentIterate
    | AGComponentValue
    | AGComponentApplicationSignalSelection;

export type AGComponent = AGComponentUnion & {
    tags?: AGComponentTags;
};

/**
 * AppTemplateSchema
 */
export type AppTemplateSchema = {
    fieldDefinitions: Record<string, AppTemplateSchema_FieldDefinition>;
    application: {
        component: AGComponent;
        lor?: {
            min: number;
            max: number;
        };
        programSelection: {
            maxSignals: number;
            maxSignalStatementWordCount: number;
        };
        programSpecificSchemas?: Record<
            number,
            AppTemplateSchema_ProgramSpecificSchema
        >;
    };
    recommender?: {
        customData: Record<string, AGComponent>;
        component: AGComponent;
    };
    review: {
        excludeApplicantTasks?: string[];
        reviewAppComponent: AGComponent;
    };
    signalConfig: AppTemplateSchema_SignalConfig;
};

export type AppTemplateSchema_FieldDefinition = {
    labelOverride?: string;
    includeInTable?: Record<string, never>;
    includeInCsv: boolean;
    excludeFromCustomizeUI?: boolean;
    customizeUILabelOverride?: string;
    component: AGComponent;
};

export type AppTemplateSchema_ProgramSpecificSchema = {
    customData: Record<string, AGComponent>;
    component: AGComponent;
};

export type AppTemplateSchema_Applicant = {
    modules: {
        customData: Record<string, AGComponent>;
        lor?: {
            min: number;
            max: number;
        };
        programSelection: {
            maxSignals: number;
            maxSignalStatementWordCount: number;
        };
    };
    component: AGComponent;
};

export type AppTemplateSchema_Recommender = {
    customData: Record<string, AGComponent>;
    component: AGComponent;
};

export type AppTemplateSchema_Reviewer = {
    fieldDefinitions: Record<string, AppTemplateSchema_FieldDefinition>;
    component: AGComponent;
    signalConfig: AppTemplateSchema_SignalConfig;
};

export type AppTemplateSchema_SignalConfig = {
    type: 'regular' | 'goldSilver';
};

export type AppTemplateSchema_TableConfig = {
    fields: Record<string, AppTemplateSchema_TableConfig_FieldConfig>;
    signalConfig: AppTemplateSchema_SignalConfig;
};

export type AppTemplateSchema_TableConfig_FieldConfig = {
    header: string;
    renderer?: AppTemplateSchema_FieldRenderer;
    searchConfig?: AppTemplateSchema_SearchConfig;
};

export type AppTemplateSchema_FieldRenderer =
    | {
          type: 'map';
          map: Record<string, string>;
      }
    | { type: 'boolean' };

export type AppTemplateSchema_SearchConfig =
    | {
          type: 'string' | 'number' | 'boolean' | 'numerical_string';
      }
    | {
          type: 'select';
          options: Record<string, string>;
          multi: boolean;
      };

/**
 * Other
 */
// Maps to patch for textarea, or actual value for all other fields
export type DataResponsesUpdate_TextArea = {
    // Hash of the current value (hash empty string if response does not exist yet)
    hash: string;
    // Delta obtained from textdiff-create
    delta: any;
};

export type DataResponsesUpdatePayload = Record<
    string,
    any | DataResponsesUpdate_TextArea
>;

export type RubricUIComponent = (
    | AGComponentInputText
    | AGComponentInputSelect
    | AGComponentInputScale
) & { weight: number | null };
const rubricUIComponentTypeMap: Record<RubricUIComponent['type'], true> = {
    select: true,
    text: true,
    inputScale: true,
};

export type AutoPopConfig = {
    popKey: string;
    pages: string[];
};

export class SharedUtil {
    static letterOfRecStatusCountsTowardsMax(
        status: LetterOfRecommendationStatus
    ): boolean {
        switch (status) {
            case LetterOfRecommendationStatus.Cancelled:
            case LetterOfRecommendationStatus.Declined:
                return false;
            default:
                return true;
        }
    }

    static lorCanRemind(
        status: LetterOfRecommendationStatus,
        lastRemind: Date | null,
        now: Date
    ): boolean {
        const validStatuses = [
            LetterOfRecommendationStatus.Accepted,
            LetterOfRecommendationStatus.PendingAcceptance,
        ];
        const lorDelayMs = 5 * 60 * 1000;
        return (
            validStatuses.includes(status) &&
            (lastRemind === null ||
                now.getTime() - lastRemind.getTime() > lorDelayMs)
        );
    }

    static parseRubricUI(rubricUI: string): RubricUIComponent[] {
        try {
            const parsed: RubricUIComponent[] = JSON.parse(rubricUI);
            if (!Array.isArray(parsed)) {
                throw new Error('not an array');
            }
            return parsed.filter((el) => el.type in rubricUIComponentTypeMap);
        } catch (e) {
            return [];
        }
    }

    static parseAGComponent(jsonStr: string): AGComponent | null {
        try {
            const parsed = JSON.parse(jsonStr);
            if (typeof parsed !== 'object') {
                throw new Error('will be caught');
            }
            // TODO more validation
            return parsed;
        } catch (e) {
            return null;
        }
    }

    static getNumWords(str: string): number {
        return this.getWords(str).length;
    }

    private static getWords(str: string): string[] {
        return str.split(/\s/).filter((p) => p.trim().length > 0);
    }

    static fixUserRoles(roles: UserRole[]) {
        const hasManager =
            roles.includes(UserRole.ProgramDirector) ||
            roles.includes(UserRole.ProgramCoordinator);
        const hasReviewer = roles.includes(UserRole.Reviewer);
        if (hasManager && !hasReviewer) {
            return [...roles, UserRole.Reviewer];
        } else {
            return roles.slice();
        }
    }

    static renderResponseForTable(
        renderer: AppTemplateSchema_FieldRenderer | undefined,
        response: any
    ): string {
        const strResponse = () => {
            if (typeof response === 'string') {
                return response;
            } else if (response == null) {
                return '';
            } else {
                // handles boolean, number, objects
                return JSON.stringify(response);
            }
        };
        if (renderer != null) {
            switch (renderer.type) {
                case 'map':
                    if (Array.isArray(response)) {
                        return response
                            .map((el) => renderer.map[el] ?? el)
                            .join(', ');
                    } else {
                        return renderer.map[response] ?? strResponse();
                    }
                case 'boolean':
                    if (response === true) {
                        return 'True';
                    } else if (response === false) {
                        return 'False';
                    } else {
                        return '';
                    }
            }
        }
        return strResponse();
    }

    static convertResponses(
        responses: Array<{ fieldKey: string; data: string }>
    ): Record<string, any> {
        const dataResponses: Record<string, any> = {};
        for (const response of responses) {
            if (!response.data) {
                continue;
            }
            try {
                dataResponses[response.fieldKey] = JSON.parse(response.data);
            } catch (e) {
                console.error('Error trying to parse JSON:', response);
                console.error(e);
            }
        }
        return dataResponses;
    }

    static isURM(raceEthnicity: RaceEthnicityKeys[]): boolean {
        return (
            raceEthnicity.includes(RaceEthnicityKeys.americanIndian) ||
            raceEthnicity.includes(RaceEthnicityKeys.black) ||
            raceEthnicity.includes(RaceEthnicityKeys.hispanic) ||
            raceEthnicity.includes(RaceEthnicityKeys.nativeHawaiian)
        );
    }
}

export class AGUtil {
    static walk(
        component: AGComponent,
        cb: (component: AGComponent) => void | boolean
    ) {
        const cont = cb(component);
        if (cont === false) return;
        if ('children' in component) {
            for (const child of component.children ?? []) {
                this.walk(child, cb);
            }
        } else if (component.type === 'table') {
            for (const row of component.rows) {
                for (const cell of row) {
                    this.walk(cell, cb);
                }
            }
        } else if (component.type === 'condition') {
            this.walk(component.component, cb);
        } else if (component.type === 'multi') {
            component.fields.forEach((field) => this.walk(field.input, cb));
        } else if (component.type === 'switch') {
            component.cases.forEach((casee) => this.walk(casee.component, cb));
            if (component.fallthrough != null) {
                this.walk(component.fallthrough, cb);
            }
        } else if (component.type === 'tasks') {
            Object.values(component.tasks).forEach((task) =>
                this.walk(task.component, cb)
            );
        }
    }

    static walkTillReturn<T>(
        component: AGComponent,
        cb: (component: AGComponent) => T | null
    ): T | null {
        let result: T | null = null;
        this.walk(component, (child) => {
            if (result !== null) return;
            result = cb(child);
        });
        return result;
    }

    static findFirst<T extends { type: string }>(
        component: AGComponent,
        type: T['type']
    ): T | null {
        return AGUtil.walkTillReturn(component, (child) => {
            if (child.type === type) {
                return child;
            } else {
                return null;
            }
        }) as any;
    }

    static findInput(component: AGComponent): AGComponentInputUnion | null {
        const result: any = this.walkTillReturn(component, (child) => {
            if (child.type in AGComponentInputTypes) {
                return child;
            }
            return null;
        });
        return result;
    }
}
