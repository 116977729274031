import { css } from '@emotion/react';
import {
    CardTick,
    HambergerMenu,
    IconProps as IconSaxProps,
    LanguageSquare,
    Link,
    Personalcard,
    Profile2User,
    SecurityCard,
} from 'iconsax-react';
import { D1ImageType } from '../generated/syncShared/d1ImageType';
import { CColor } from '../util/commonCSS';
import ResAnesthesiology from './anesthesiology.svg';
import ResBrain from './brain.svg';
import ResCircleX from './circleX.svg';
import ResClock from './clock.svg';
import ResDangerRed from './dangerRed.svg';
import ResDangerYellow from './dangerYellow.svg';
import ResDownload from './download.svg';
import ResEyeClose from './eyeClose.svg';
import ResEyeOpen from './eyeOpen.svg';
import ResGradCap from './gradCap.svg';
import ResGreenArrowDown from './greenArrowDown.svg';
import ResGreenArrowLeft from './greenArrowLeft.svg';
import ResGreenCheck from './greenCheck.svg';
import ResGreenCheckTime from './greenCheckTime.svg';
import { HeroUserGroup } from './heroUserGroup';
import { IconStudent } from './iconStudent';
import { IconUserCheck } from './iconUserCheck';
import ResLetterPaper from './letterPaper.svg';
import ResNeurology from './neurology.svg';
import ResPlasticSurgery from './plasticSurgery.svg';
import ResPreviousApplicant from './previousApplicant.svg';
import ResQuestion from './question.png';
import ResRedX from './redX.svg';
import ResRightArrowBlue from './rightArrowBlue.svg';
import ResRightArrowGray from './rightArrowGray.svg';
import ResScoreIcon from './scoreIcon.svg';
import ResScoreIconGreen from './scoreIconGreen.svg';
import ResSearch from './search.svg';
import ResStar from './star.svg';
import { IconProps } from './types';
import ResUploadArrow from './uploadArrow.svg';

type ImageTypeMapValue =
    | string
    | {
          type: 'node';
          component: React.ReactNode;
      }
    | {
          type: 'colornode';
          component: React.ComponentType<IconProps>;
      };

function v(Component: React.ComponentType<IconSaxProps>): ImageTypeMapValue {
    return {
        type: 'node',
        component: (
            <Component
                css={css({ width: '100%', height: '100%' })}
                color={CColor.h249690}
            />
        ),
    };
}

function c(component: React.ComponentType<IconProps>): ImageTypeMapValue {
    return {
        type: 'colornode',
        component,
    };
}

export const IMAGE_TYPE_TO_SRC: Record<D1ImageType, ImageTypeMapValue> = {
    [D1ImageType.Brain]: ResBrain,
    [D1ImageType.GreenArrowLeft]: ResGreenArrowLeft,
    [D1ImageType.GreenArrowDown]: ResGreenArrowDown,
    [D1ImageType.GreenCheck]: ResGreenCheck,
    [D1ImageType.Student]: c(IconStudent),
    [D1ImageType.PlasticSurgery]: ResPlasticSurgery,
    [D1ImageType.Clock]: ResClock,
    [D1ImageType.RightArrowBlue]: ResRightArrowBlue,
    [D1ImageType.UploadArrow]: ResUploadArrow,
    [D1ImageType.RightArrowGray]: ResRightArrowGray,
    [D1ImageType.Download]: ResDownload,
    [D1ImageType.Search]: ResSearch,
    [D1ImageType.EyeOpen]: ResEyeOpen,
    [D1ImageType.EyeClose]: ResEyeClose,
    [D1ImageType.CircleX]: ResCircleX,
    [D1ImageType.Question]: ResQuestion,
    [D1ImageType.DangerRed]: ResDangerRed,
    [D1ImageType.DangerYellow]: ResDangerYellow,
    [D1ImageType.GreenCheckTime]: ResGreenCheckTime,
    [D1ImageType.GradCap]: ResGradCap,
    [D1ImageType.LetterPaper]: ResLetterPaper,
    [D1ImageType.ScoreIcon]: ResScoreIcon,
    [D1ImageType.ScoreIconGreen]: ResScoreIconGreen,
    [D1ImageType.Anesthesiology]: ResAnesthesiology,
    [D1ImageType.Neurology]: ResNeurology,
    [D1ImageType.Star]: ResStar,
    [D1ImageType.VueProfile2User]: v(Profile2User),
    [D1ImageType.VueCardTick]: v(CardTick),
    [D1ImageType.VueLink]: v(Link),
    [D1ImageType.VuePersonalCard]: v(Personalcard),
    [D1ImageType.VueSecurityCard]: v(SecurityCard),
    [D1ImageType.VueLanguageSquare]: v(LanguageSquare),
    [D1ImageType.VueHambergerMenu]: v(HambergerMenu),
    [D1ImageType.PreviousApplicant]: ResPreviousApplicant,
    [D1ImageType.RedX]: ResRedX,
    [D1ImageType.HeroUserGroup]: c(HeroUserGroup),
    [D1ImageType.UserCheck]: c(IconUserCheck),
};
