// [HybridCodegen] hash e0af59a54a23b52fbab3521962ff0b8f

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';

const Component = lazy(async () => {
    const module = await import(
        '../../../pages/faculty/home/facultyHome.route'
    );
    const Component = module.RouteFacultyHome.component;
    const wrappers = module.RouteFacultyHome.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
export const FacultyHomeRouteIndex: RouteObject = {
    index: true,
    element: <Component />,
};
