export type TLocalStorage = {
    TableColumnWidths: Record<string, Record<string, number>>;
    TableSort: Record<
        string,
        {
            col: number;
            asc: boolean;
        }
    >;
    Cookies: Record<string, string>;
};

type TKeys<T> = {
    [K in keyof T]: K;
};

export const LocalStorageKey: TKeys<TLocalStorage> = {
    TableColumnWidths: 'TableColumnWidths',
    TableSort: 'TableSort',
    Cookies: 'Cookies',
};

export class LocalStorage {
    static set<T extends keyof TLocalStorage>(key: T, value: TLocalStorage[T]) {
        window.localStorage.setItem(key, JSON.stringify(value));
    }

    static get<T extends keyof TLocalStorage>(key: T): TLocalStorage[T] | null {
        try {
            const saved = window.localStorage.getItem(key);
            if (saved === null) {
                return null;
            }
            return JSON.parse(saved);
        } catch (e) {
            return null;
        }
    }

    static delete(key: keyof TLocalStorage): void {
        window.localStorage.removeItem(key);
    }
}
