import { css } from '@emotion/react';
import { useCallback, useContext, useMemo, useState } from 'react';
import { D1Button, D1ButtonStyle } from '../../components/design/d1button';
import { D1Flex } from '../../components/design/d1flex';
import D1Image from '../../components/design/d1image';
import { D1Modal } from '../../components/design/d1modal/d1modal';
import { D1ImageType } from '../../generated/syncShared/d1ImageType';
import { AutoPopConfig } from '../../generated/syncShared/syncShared';
import { ChildrenProps } from '../../types/generalTypes';
import { executeAsync, Util } from '../../util/util';
import { useAuthContext } from '../authContext/authContext';
import { executeMutAutoPopContext_SetShown } from './autoPopContext.query';

type TAutoPopContext = {
    setAutoPop: (autoPop: AutoPopConfig | null) => void;
};

const AutoPopContext = Util.createContext<TAutoPopContext>();

export function ProvideAutoPopContext({ children }: ChildrenProps) {
    const { state, setState } = useAuthContext();
    const shownPopKeys = useMemo(
        () =>
            state.type === 'loggedIn'
                ? state.frontendAuthInfo.autoPopApi.shownPopKeys
                : [],
        [state]
    );
    const [autoPop, setAutoPop] = useState<AutoPopConfig | null>(null);
    const [page, setPage] = useState<number | null>(null);
    const onClose = useCallback(() => {
        setPage(null);
        if (autoPop !== null && !shownPopKeys.includes(autoPop.popKey)) {
            executeAsync(async () => {
                if (state.type !== 'loggedIn') {
                    return;
                }
                await executeMutAutoPopContext_SetShown(
                    state.frontendAuthInfo,
                    autoPop.popKey,
                    true
                );
                setState({
                    ...state,
                    frontendAuthInfo: {
                        ...state.frontendAuthInfo,
                        autoPopApi: {
                            ...state.frontendAuthInfo.autoPopApi,
                            shownPopKeys: [...shownPopKeys, autoPop.popKey],
                        },
                    },
                });
            });
        }
    }, [autoPop, setState, shownPopKeys, state]);
    return (
        <>
            <AutoPopContext.Provider
                value={{
                    setAutoPop: useCallback(
                        (autoPop: AutoPopConfig | null) => {
                            setAutoPop(autoPop);
                            if (
                                autoPop !== null &&
                                !shownPopKeys.includes(autoPop.popKey)
                            ) {
                                setPage(0);
                            }
                        },
                        [shownPopKeys]
                    ),
                }}
            >
                {children}
            </AutoPopContext.Provider>
            {autoPop !== null && (
                <>
                    <div
                        css={css({
                            zIndex: 5000,
                            border: '2px solid white',
                            width: '34px',
                            height: '34px',
                            borderRadius: '34px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: 'black',
                            position: 'fixed',
                            right: '5px',
                            bottom: '5px',
                            opacity: '0.4',
                            cursor: 'pointer',
                            ':hover': {
                                transform: 'scale(1.1)',
                            },
                        })}
                        onClick={() => setPage(0)}
                    >
                        <D1Image image={D1ImageType.Question} width={16} />
                    </div>
                    <D1Modal purpose="autoPopModal" show={page !== null}>
                        {page !== null && (
                            <>
                                <div>{autoPop.pages[page]}</div>
                                <D1Flex
                                    dir="x"
                                    gap={10}
                                    justifyContent="right"
                                    css={css({ marginTop: '20px' })}
                                >
                                    {page > 0 && (
                                        <D1Button
                                            style={D1ButtonStyle.Secondary({
                                                size: 'md',
                                            })}
                                            onClick={() => setPage(page - 1)}
                                        >
                                            Previous
                                        </D1Button>
                                    )}
                                    {page < autoPop.pages.length - 1 && (
                                        <D1Button
                                            style={D1ButtonStyle.Success({
                                                size: 'md',
                                            })}
                                            onClick={() => setPage(page + 1)}
                                        >
                                            Next
                                        </D1Button>
                                    )}
                                    {page === autoPop.pages.length - 1 && (
                                        <D1Button
                                            style={D1ButtonStyle.Success({
                                                size: 'md',
                                            })}
                                            onClick={onClose}
                                        >
                                            Close
                                        </D1Button>
                                    )}
                                </D1Flex>
                            </>
                        )}
                    </D1Modal>
                </>
            )}
        </>
    );
}

export function useAutoPopContext(): TAutoPopContext {
    return useContext(AutoPopContext);
}
