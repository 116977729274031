// [HybridCodegen] hash 04ac60a65c242878f9333417af15c5f4

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';
import * as ApplicantMessagesRouteChildren from './applicantMessages';
import * as ApplicantAppInstanceRouteChildren from './applicantAppInstance';
import * as ApplicantApplicationManagerRouteChildren from './applicantApplicationManager';
import * as ApplicantArchiveRouteChildren from './applicantArchive';
import * as ApplicantDocumentsRouteChildren from './applicantDocuments';
import * as ApplicantHomeRouteChildren from './applicantHome';
import * as ApplicantPreviewAppTemplateRouteChildren from './applicantPreviewAppTemplate';
import * as ApplicantInterviewManagerRouteChildren from './applicantInterviewManager';

const Component = lazy(async () => {
    const module = await import('../../../pages/applicant/applicant.route');
    const Component = module.RouteApplicant.component;
    const wrappers = module.RouteApplicant.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
export const ApplicantRoute: RouteObject = {
    path: '/applicant',

    element: <Component />,
    children: [
        ...Object.values(ApplicantMessagesRouteChildren),
        ...Object.values(ApplicantAppInstanceRouteChildren),
        ...Object.values(ApplicantApplicationManagerRouteChildren),
        ...Object.values(ApplicantArchiveRouteChildren),
        ...Object.values(ApplicantDocumentsRouteChildren),
        ...Object.values(ApplicantHomeRouteChildren),
        ...Object.values(ApplicantPreviewAppTemplateRouteChildren),
        ...Object.values(ApplicantInterviewManagerRouteChildren),
    ],
};
