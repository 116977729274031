// [HybridCodegen] hash bc3a7afb43770e5dae5b92e0830ed9bd

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';

const Component = lazy(async () => {
    const module = await import('../../../pages/userNoProfiles.route');
    const Component = module.RouteUserNoProfiles.component;
    const wrappers = module.RouteUserNoProfiles.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
export const UserNoProfilesRoute: RouteObject = {
    path: '/noProfiles',

    element: <Component />,
};
