// [HybridCodegen] hash 1be66f90e5add17a01691d55300b9419

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';
import withParams from '../../../util/withParams';

const Component = lazy(async () => {
    const module = await import(
        '../../../pages/public/verifyEmail/verifyEmail.route'
    );
    const Component = module.RouteVerifyEmail.component;
    const wrappers = module.RouteVerifyEmail.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
const WithParams = withParams(Component, { verifyKey: 'string' });
export const VerifyEmailRoute: RouteObject = {
    path: '/verifyEmail/:verifyKey',

    element: <WithParams />,
};
