import { useInit } from '../hooks/useInit';
import { ChildrenProps } from '../types/generalTypes';

export class UnsavedChanges {
    private static unsavedChanges: Record<string, null> = {};

    public static set(key: string) {
        UnsavedChanges.unsavedChanges[key] = null;
    }

    public static unset(key: string) {
        delete UnsavedChanges.unsavedChanges[key];
    }

    public static hasUnsavedChanges(): boolean {
        return Object.keys(UnsavedChanges.unsavedChanges).length > 0;
    }
}

export function ProvideUnsavedChanges(props: ChildrenProps) {
    useInit(() => {
        window.addEventListener('beforeunload', function (e) {
            if (!UnsavedChanges.hasUnsavedChanges()) return undefined;
            e.preventDefault();
            e.stopImmediatePropagation();
            const confirmationMessage = 'Changes you made may not be saved.';
            (e || window.event).returnValue = confirmationMessage; //Gecko + IE
            return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
        });
    });
    return <>{props.children}</>;
}
