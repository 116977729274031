import { useEffect, useState } from 'react';
import { Util } from '../../util/util';
import { ArrowOption, ArrowOptions } from '../arrowOptions/arrowOptions';

export type FilterArrowOptionsProps = {
    options: ArrowOption[];
    onSelect: (optionID: string) => void;
    filter: string;
    maxHeight?: number;
    width?: number;
    defaultOptionID?: string;
    excludeAndAlwaysShow?: string[];
};

export default function FilterArrowOptions({
    options,
    onSelect,
    filter,
    maxHeight,
    width,
    defaultOptionID,
    excludeAndAlwaysShow,
}: FilterArrowOptionsProps) {
    const [hovered, setHovered] = useState<string | null>(
        defaultOptionID ?? null
    );
    const [filteredOptions, setFilteredOptions] = useState<ArrowOption[]>([]);

    useEffect(() => {
        const newFiltered = options.filter(
            (opt) =>
                Util.containsInsensitive(opt.display, filter.trim()) ||
                (excludeAndAlwaysShow != null &&
                    excludeAndAlwaysShow.includes(opt.id))
        );
        setFilteredOptions(newFiltered);
        const selected = newFiltered.filter((opt) => opt.id === hovered)[0];
        if (selected == null && newFiltered.length > 0) {
            setHovered(newFiltered[0].id);
        }
    }, [options, filter, hovered, excludeAndAlwaysShow]);

    useEffect(() => {
        setHovered(defaultOptionID ?? null);
    }, [defaultOptionID]);

    if (filteredOptions.length === 0) {
        return null;
    }

    const hoveredNonNull = hovered ?? filteredOptions[0].id;
    return (
        <ArrowOptions
            options={filteredOptions}
            hovered={hoveredNonNull}
            onHover={setHovered}
            onSelect={() => onSelect(hoveredNonNull)}
            maxHeight={maxHeight}
            width={width}
        />
    );
}
