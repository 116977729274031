// [HybridCodegen] hash e140e817a08df111778a2f05302a2036
// [HybridCodegen] begin-manual-section manual-imports
import { executeQueryNoAuth as executeQuery } from '../../util/superqlUtil';
// [HybridCodegen] end-manual-section manual-imports
export type QueryAuthContext_RootPageLoad = {
    o: {
        __name?: 'Root';
        publicApi: {
            __name?: 'PublicApi';
            userAuthApi: {
                __name?: 'UserAuthApi';
                refresh: string | null;
            };
        };
        globalSettings: {
            __name?: 'GlobalSettings';
            globalSettingsJson: string;
        };
    };
    requirements: null;
};
async function executeQueryAuthContext_RootPageLoad(): Promise<
    QueryAuthContext_RootPageLoad['o']
> {
    return await executeQuery('cad72e822eea631489900a6e8addf96b', {});
}
// [HybridCodegen] begin-manual-section manual-footer
export { executeQueryAuthContext_RootPageLoad };

// [HybridCodegen] end-manual-section manual-footer
