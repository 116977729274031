import { Config, Infer } from '@yesness/superql-react';
import { useMemo, useRef, useState } from 'react';
import { AccessTokenProvider } from '../contexts/authContext/authContext';
import { SET_ROOT_ERROR, SET_ROOT_MESSAGE } from '../contexts/rootErrorContext';
import {
    API_STATUS_CODES,
    TMP_COOKIES_RESPONSE_KEY,
} from '../generated/syncShared/syncShared';
import { useEffectUnsafe } from '../hooks/useEffectUnsafe';
import { useInit } from '../hooks/useInit';
import {
    LoginSuccessResponse,
    UserAuthMFAToken,
} from '../superql-generated/objects';
import { LocalStorage } from './localStorage';
import { Util, wrapAsync } from './util';

let apiUrlCache: string | null = null;
async function getApiUrl(): Promise<string> {
    const devUrl = 'https://w1tkraa99a.execute-api.us-west-2.amazonaws.com';
    if (apiUrlCache === null) {
        apiUrlCache = `https://niu3bfxu13.execute-api.us-west-2.amazonaws.com/`;
        switch (window.location.host) {
            case 'localhost:1234':
                apiUrlCache = `http://localhost:8080/`;
                try {
                    const controller = new AbortController();
                    const timeoutID = setTimeout(() => controller.abort(), 100);
                    await fetch(apiUrlCache + '?version=1', {
                        signal: controller.signal,
                    });
                    clearTimeout(timeoutID);
                } catch (e) {
                    console.log(
                        `No local backend running, falling back to sandbox`
                    );
                    apiUrlCache = devUrl;
                }
                break;
            // TODO remove this once DNS is switched
            case 'dev.d2yt0xl68k3szg.amplifyapp.com':
            case 'dev.accessgme.com':
                apiUrlCache = devUrl;
                break;
        }
    }
    return apiUrlCache;
}

export type SuperObjectWithID<TName> = {
    __name?: TName;
    id: number;
};

export type CommonConfig = {
    UserAuthMFAToken: Config<
        UserAuthMFAToken,
        {
            token: Infer;
            message: Infer;
            mfaSetup: Infer;
        }
    >;
    LoginSuccessResponse: Config<
        LoginSuccessResponse,
        {
            accessToken: Infer;
            verifyEmailToken: Infer;
        }
    >;
};

type ExecuteQueryParams = {
    hash: string;
    variables: Record<string, any>;
    noAuth?: boolean;
};

export async function executeQuery(
    hash: string,
    variables: Record<string, any>
): Promise<any> {
    return await executeQueryWrapper({ hash, variables });
}

export async function executeQueryNoAuth(
    hash: string,
    variables: Record<string, any>
): Promise<any> {
    return await executeQueryWrapper({ hash, variables, noAuth: true });
}

function executeQueryWrapper(params: ExecuteQueryParams): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await executeQueryImpl(params);
            if (result.type === 'success') {
                resolve(result.response);
            } else {
                const now = Util.now();
                const remainingMS = result.stop - now;
                const remainingSecs = Math.floor(remainingMS / 1000);
                const remainingMins = Math.max(
                    Math.round(remainingSecs / 60),
                    1
                );
                let timeText;
                if (remainingMins >= 60) {
                    const hours = Math.round(remainingMins / 60);
                    timeText = `${hours} hour${Util.plural(hours)}`;
                } else {
                    timeText = `${remainingMins} minute${Util.plural(
                        remainingMins
                    )}`;
                }
                SET_ROOT_MESSAGE(
                    `Central app is down for maintenance. Estimated time remaining: ${timeText}`
                );
            }
        } catch (e) {
            reject(e);
        }
    });
}

type ExecuteQueryResponse =
    | {
          type: 'success';
          response: any;
      }
    | {
          type: 'maintenance';
          stop: number;
      };

let globalRequestID = 1;

async function executeQueryImpl(
    params: ExecuteQueryParams
): Promise<ExecuteQueryResponse> {
    const reqPrefix = `[${globalRequestID++}]`;
    Util.devLog(reqPrefix, 'executeQuery', params);
    const headers: Record<string, string> = {
        'Content-Type': 'application/json',
    };
    if (!params.noAuth) {
        const accessToken = await AccessTokenProvider.getAccessToken();
        if (accessToken !== null) {
            headers.Authorization = accessToken;
        }
    }
    const apiUrl = await getApiUrl();
    const curCookies = LocalStorage.get('Cookies') ?? {};
    const body: any = {
        hash: params.hash,
        variableValues: params.variables,
        [TMP_COOKIES_RESPONSE_KEY]: curCookies,
    };
    const response = await fetch(apiUrl, {
        method: 'POST',
        body: JSON.stringify(body),
        headers,
        credentials: 'include',
    });
    if (response.status === 200) {
        const json = await response.json();
        Util.devLog(reqPrefix, 'response', params.hash, json);
        const newCookies = json[TMP_COOKIES_RESPONSE_KEY];
        if (typeof newCookies === 'object') {
            LocalStorage.set('Cookies', {
                ...curCookies,
                ...newCookies,
            });
        }
        return {
            type: 'success',
            response: json,
        };
    } else if (response.status === API_STATUS_CODES.maintenance) {
        const bodyText = await response.text();
        const stop = parseInt(bodyText);
        if (isNaN(stop)) {
            throw new Error(`Invalid maintenance stop: ${bodyText}`);
        }
        return {
            type: 'maintenance',
            stop,
        };
    } else {
        throw new Error('Failed to load data');
    }
}

type ConvertToHookResult<R, T extends Array<any>> = [
    R | null,
    {
        setData: React.Dispatch<React.SetStateAction<R | null>>;
        reload: () => Promise<void>;
        // When called, will not rerun the query whenever input args change to
        // whatever was passed to preventUpdate
        preventUpdate: (...args: T) => void;
    }
];

export function convertQueryToHook<T extends Array<any>, R>(
    query: (...args: T) => Promise<R>,
    options?: {
        withUpdater?: boolean;
    }
): (...args: T) => ConvertToHookResult<R, T> {
    return (...args: T) => {
        const preventUpdate = useRef<T | null>(null);
        const [result, setResult] = useState<R | null>(null);
        const resultWithUpdater = useMemo(() => {
            if (result == null || !options?.withUpdater) {
                return result;
            }
            SuperQLUtil.injectUpdater(result, setResult);
            return result;
        }, [result]);
        useEffectUnsafe(() => {
            if (
                preventUpdate.current !== null &&
                Util.sameDeps(args, preventUpdate.current)
            ) {
                return;
            }
            query(...args)
                .then(setResult)
                .catch((e) => {
                    SET_ROOT_ERROR('convertQueryToHook', e);
                });
        }, [...args]);
        const util = useMemo(
            () => ({
                setData: setResult,
                preventUpdate: (...args: T) => {
                    preventUpdate.current = args;
                },
                reload: async () =>
                    await wrapAsync(async () => {
                        const result = await query(...args);
                        setResult(result);
                    }),
            }),
            [args]
        );
        return [resultWithUpdater, util];
    };
}

type ConvertToHookNoArgsResult<R> = [
    R | null,
    {
        setData: React.Dispatch<React.SetStateAction<R | null>>;
    }
];

export function convertQueryToHookNoArgs<R>(
    query: () => Promise<R>
): () => ConvertToHookNoArgsResult<R> {
    return () => {
        const [result, setResult] = useState<R | null>(null);
        useInit(() => {
            query()
                .then(setResult)
                .catch((e) => {
                    console.error(e);
                    // TODO error handling
                });
        });
        const util = useMemo(() => ({ setData: setResult }), []);
        return [result, util];
    };
}

type SuperQLObjUpdate<TObj> = TObj extends Array<any> ? TObj : Partial<TObj>;

export class SuperQLUtil {
    private static updaterKey = '__superql_updater';

    static injectUpdater<TObj>(obj: TObj, updater: (obj: TObj) => void): void {
        if (obj == null || typeof obj !== 'object') {
            // can't inject for this case
            return;
        }
        Object.defineProperty(obj, this.updaterKey, {
            enumerable: false,
            writable: true,
            value: updater,
        });
        if (Array.isArray(obj)) {
            obj.forEach((child, idx) => {
                this.injectUpdater(child, (newChild) => {
                    // console.log('array updater', { obj, idx, child, newChild });
                    const newObj: any = obj.slice();
                    newObj[idx] = newChild;
                    updater(newObj);
                });
            });
        } else {
            for (const [key, val] of Object.entries(obj)) {
                this.injectUpdater(val, (newVal) => {
                    // console.log('object updater', { obj, key, val, newVal });
                    updater({
                        ...obj,
                        [key]: newVal,
                    });
                });
            }
        }
    }

    static updateObject<TObj>(obj: TObj, update: SuperQLObjUpdate<TObj>) {
        const updater: (obj: TObj) => void = (obj as any)[this.updaterKey];
        if (Array.isArray(obj)) {
            updater(update as any);
        } else {
            updater({
                ...obj,
                ...update,
            });
        }
    }
}
