// [HybridCodegen] hash 26c055f5f38f7f5125132a4262353180

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';
import withParams from '../../../util/withParams';

const Component = lazy(async () => {
    const module = await import(
        '../../../pages/faculty/groups/viewGroup/facultyViewGroup.route'
    );
    const Component = module.RouteFacultyViewGroup.component;
    const wrappers = module.RouteFacultyViewGroup.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
const WithParams = withParams(Component, { group: 'int' });
export const FacultyViewGroupRoute: RouteObject = {
    path: 'viewGroup/:group',

    element: <WithParams />,
};
