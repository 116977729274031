// [HybridCodegen] hash e91fb7b94f0b402a0160e645934fb9e9

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';
import * as AppAssisterHomeRouteChildren from './appAssisterHome';
import * as AppAssisterViewMSPERouteChildren from './appAssisterViewMSPE';
import * as AppAssisterViewTranscriptRouteChildren from './appAssisterViewTranscript';

const Component = lazy(async () => {
    const module = await import('../../../pages/appAssister/appAssister.route');
    const Component = module.RouteAppAssister.component;
    const wrappers = module.RouteAppAssister.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
export const AppAssisterRoute: RouteObject = {
    path: '/appAssister',

    element: <Component />,
    children: [
        ...Object.values(AppAssisterHomeRouteChildren),
        ...Object.values(AppAssisterViewMSPERouteChildren),
        ...Object.values(AppAssisterViewTranscriptRouteChildren),
    ],
};
