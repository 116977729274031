import { useCallback, useContext, useEffect, useState } from 'react';
import { executeQueryInputFile_UserPhoto } from '../components/input/inputFile/inputFile.query';
import { FileApiOperation } from '../superql-generated/objects';
import { ChildrenProps } from '../types/generalTypes';
import { executeAsync, Util } from '../util/util';
import { useAuthContext } from './authContext/authContext';

type TUserPhotoContext = {
    profilePicUrl: string | null;
    loading: boolean;
    refresh: () => void;
};

const UserPhotoContext = Util.createContext<TUserPhotoContext>();

export function ProvideUserPhotoContext({ children }: ChildrenProps) {
    const { state } = useAuthContext();
    const [profilePicUrl, setProfilePicUrl] = useState<string | null>(null);
    const loadProfilePic = useCallback(
        () =>
            executeAsync(async () => {
                if (state.type === 'loggedOut') {
                    return;
                }
                const result = await executeQueryInputFile_UserPhoto(
                    state.frontendAuthInfo,
                    FileApiOperation.Download
                );
                setProfilePicUrl(result.fileApi.getSignedUrl);
            }),
        [state]
    );
    useEffect(() => {
        if (state.type === 'loggedOut' && profilePicUrl !== null) {
            setProfilePicUrl(null);
        }
        if (
            state.type === 'loggedOut' ||
            !state.frontendAuthInfo.photoUploaded ||
            profilePicUrl !== null
        ) {
            return;
        }
        loadProfilePic();
    }, [loadProfilePic, profilePicUrl, state]);
    return (
        <UserPhotoContext.Provider
            value={{
                profilePicUrl,
                loading:
                    profilePicUrl === null &&
                    state.type === 'loggedIn' &&
                    state.frontendAuthInfo.photoUploaded,
                refresh: loadProfilePic,
            }}
        >
            {children}
        </UserPhotoContext.Provider>
    );
}

export function useUserPhotoContext(): TUserPhotoContext {
    return useContext(UserPhotoContext);
}
