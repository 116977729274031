// [HybridCodegen] hash eb448e23a980ec1fc456680dca095135
// This file is synced from backend
export enum RaceEthnicityKeys {
    preferNot = 'preferNot',
    americanIndian = 'americanIndian',
    asian = 'asian',
    black = 'black',
    hispanic = 'hispanic',
    nativeHawaiian = 'nativeHawaiian',
    white = 'white',
    other = 'other',
}
